import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc,doc,getDoc,setDoc } from 'firebase/firestore';
import { campaignDocumentId } from '../config/Constants';

export default function AdminCampaignList() {
  const [savedCampaigns, setSavedCampaigns] = useState([]);

  /*  monjardintest 
  const firebaseConfig = {
    apiKey: "AIzaSyBqg2nJJj4pxevSA7I_N2fj36-R5Jhvqbo",
    authDomain: "monjardintest-1bd6a.firebaseapp.com",
    projectId: "monjardintest-1bd6a",
    storageBucket: "monjardintest-1bd6a.appspot.com",
    messagingSenderId: "970382110934",
    appId: "1:970382110934:web:944389b07eff9f9b3d5977"
  };
  */


  const firebaseConfig = {
    apiKey: "AIzaSyBVljeCIm_rhZBx0522TXkNa4G4ufKoMLY",
    authDomain: "monjardin-7cc13.firebaseapp.com",
    projectId: "monjardin-7cc13",
    storageBucket: "monjardin-7cc13.appspot.com",
    messagingSenderId: "81286471664",
    appId: "1:81286471664:web:51a5c1de2c61b4551b4735",
    measurementId: "G-5BW4TRMJDR"
  }; 
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGetDocument = async (documentId) => {
    try {
      const docRef = doc(firestore, 'campaign', documentId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data().campaigns;
        setSavedCampaigns(data);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  useEffect(() => {
    handleGetDocument(campaignDocumentId);
  }, []); 


  const handleSaveChanges = async () => {
    try {
      const campaignCollection = collection(firestore, 'campaign');
      const docRef = doc(campaignCollection, 'RVHyIkoYSr9SR6iCGGXr');
      await setDoc(docRef, { campaigns: savedCampaigns }, { merge: true });
      window.location.reload();

      toast.success('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      toast.error('Error saving changes. Please try again.');
    }
  };




  const handleInputChange = (index, field, value) => {
    const updatedCampaigns = [...savedCampaigns];
    updatedCampaigns[index] = {
      ...updatedCampaigns[index],
      [field]: field === 'minimumShippingAmount' ? parseInt(value, 10) : parseFloat(value),
    };
    console.log("updateddd",updatedCampaigns)
    setSavedCampaigns(updatedCampaigns);
  };


  
  
  const renderTableRows = () => {
    return savedCampaigns.map((campaign, index) => (
    <>
      <tr key={index}>
        <td>
          <label>Minimum Toplam Tutar</label>
          
          <input
            type="text"
            id={`minTutarInput-${index}`}
            placeholder="Minimum Toplam Tutar"
            value={campaign.minimumShippingAmount || ''}
            onChange={(e) => handleInputChange(index, 'minimumShippingAmount', e.target.value)}
          />
        </td>
        <td>
        <label>Kazandırılacak İndirim Tutarı</label>  
          <input
            type="text"
            id={`indirimInput-${index}`}
            placeholder="Kazandırılacak İndirim Tutarı"
            value={campaign.discountAmount || ''}
            onChange={(e) => handleInputChange(index, 'discountAmount', e.target.value)}
          />
        </td>
        
       
      </tr>
      
         </>
    
    ));
  };



  return (
    <div style={{ margin: "50px" }}>
      <ToastContainer />
      <h1 className='baslik'>Mon Jardin</h1>

             {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}

      <div style={{ marginTop: "50px" }}>
        <table className='table table-light'>
          <thead></thead>
          <tbody>{renderTableRows()}</tbody>
          
        </table>
      </div>
      <button         
        onClick={handleSaveChanges}  
        style={{
          height: "40px",
          float:"right",   
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 10px",
          borderRadius: "5px",
          backgroundColor: "#893694",
          border: "1px solid gray",
          boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",   
          zIndex: "999",     
          marginTop:"15px"
        }}
      >
        Tümünü Kaydet
      </button>
    </div>
  );
}
