import React, { useState,useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { getToken, setUserSession,setUserInfo } from "../service/AuthService";
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from '../config/Constants';
import WithNavbar from '../WithNavbar'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, collection, getDocs ,query,where,addDoc,doc,setDoc} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const LogIn = () => {
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");

const navigate = useNavigate();

const firebaseConfig = {
  apiKey: "AIzaSyBVljeCIm_rhZBx0522TXkNa4G4ufKoMLY",
  authDomain: "monjardin-7cc13.firebaseapp.com",
  projectId: "monjardin-7cc13",
  storageBucket: "monjardin-7cc13.appspot.com",
  messagingSenderId: "81286471664",
  appId: "1:81286471664:web:51a5c1de2c61b4551b4735",
  measurementId: "G-5BW4TRMJDR"
}; 

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);


const handleEmailChange = (e) => {
  setEmail(e.target.value);
};

const handlePasswordChange = (e) => {
  setPassword(e.target.value);
};

const handleSubmit = (e) => {
  e.preventDefault();
};


const fetchProfilVerileri = async (email,token) => {
  console.log("buraya geldiiiii",email,token);
  try {  
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    
   await fetch(baseUrl+`api/User/UserProfile/${email}`,requestOptions)
    .then(response => response.json())
    .then(async(data) => {
      if (data && data.data) {
      const { firstname, lastName, phoneNumber, email, dateOfBirth } = data.data;
      console.log("phoneee", phoneNumber)
     
      const firestore = getFirestore();
      const usersCollection = collection(firestore, 'users');
      
      const userQuery = query(usersCollection, where('userId', '==',  data.userId));
      const usersSnapshot = await getDocs(userQuery);
          if (usersSnapshot.empty) {
            console.log("boş depillllll")
  
            const newUserData = {
                userId:  data.userId,
                totalAmountEarned: 0,
                phoneNumber: phoneNumber
            };
            await addDoc(usersCollection, newUserData);
        
            console.log('Yeni kullanıcı eklendi:',  data.userId);
        
          } 
          else {
              console.log('Kullanıcı zaten var:', data.userIdrId, phoneNumber);

            const firestore = getFirestore();
            const usersCollection = collection(firestore, 'users');

            const userQuery = query(usersCollection, where('userId', '==', data.userId));
            const usersSnapshot = await getDocs(userQuery);

            for await (const userDoc of usersSnapshot.docs) {
              try {
                const userDocRef = doc(firestore, 'users', userDoc.id);
                await setDoc(userDocRef, { phoneNumber: phoneNumber }, { merge: true });
                console.log('telefon nuamarası güncellendi.');
              } catch (error) {
                console.error('Error updating:', error);
              }
            }
      
      
          }
     // setUser(updatedUser);
    } else {
    //  console.error('Profil verileri alınamadı');
    }
    })
    .catch(error => {
    //  console.error(error);
    });
  } catch (error) {
  //  console.error("Profil verileri getirilirken hata oluştu: ", error);
  }
};
const handleLoginFormSubmit = async () => {
 
  if (!email  || !password) {
    toast.error('Lütfen Tüm Alanları Doldurunuz.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
    return; 
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    toast.error('Lütfen geçerli formatta bir email adresi giriniz.', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
    return; 
  }

  fetch(baseUrl+"api/auth/login", {
  method: "POST",
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
  email: email,
  password: password
  }),
  })
  .then(async (response) => {
    return response.json().then((data) => {
      if (!response.ok) {
        const errorMessage =
          data.message || "Bilinmeyen bir hata oluştu"; 
        if (response.status === 401) {
        
          throw new Error("Yetkisiz erişim. Lütfen giriş yapın.");
        } else if (response.status === 403) {
      
          throw new Error("Bu işlemi gerçekleştirmek için yetkiniz yok.");
        } else {
          throw new Error(errorMessage);
        }
      }
      return data;
    });
  })
  .then(async (data) => {
    console.log("data", data.userId);
    let gelenUserId = data.userId;
    try {  
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      };
      
     await fetch(baseUrl+`api/User/UserProfile/${email}`,requestOptions)
      .then(response => response.json())
      .then(async(data) => {
        if (data && data.data) {
        const { firstname, lastName, phoneNumber, email, dateOfBirth } = data.data;
        console.log("phoneee", phoneNumber)
      
        const last10Digits = phoneNumber.substring(Math.max(0, phoneNumber.length - 10));

        console.log("phoneee", last10Digits);


        const firestore = getFirestore();
        const usersCollection = collection(firestore, 'users');
        
        const userQuery = query(usersCollection, where('userId', '==',  gelenUserId));
        const usersSnapshot = await getDocs(userQuery);
        if (usersSnapshot.empty) {
            const newUserData = {
                userId:  gelenUserId,
                totalAmountEarned: 0,
                phoneNumber: last10Digits
            };
            await addDoc(usersCollection, newUserData);
        
            console.log('Yeni kullanıcı eklendi:');
        } else {
            console.log('Kullanıcı zaten var:', );
            const firestore = getFirestore();
            const usersCollection = collection(firestore, 'users');

            const userQuery = query(usersCollection, where('userId', '==', gelenUserId));
            const usersSnapshot = await getDocs(userQuery);

            for await (const userDoc of usersSnapshot.docs) {
              try {
                const userDocRef = doc(firestore, 'users', userDoc.id);
                await setDoc(userDocRef, { phoneNumber: last10Digits }, { merge: true });
                console.log('telefon nuamarası güncellendi.',gelenUserId);
              } catch (error) {
                console.error('Error updating:', error);
              }
            }
        }
    

        




      } else {
       console.error('Profil verileri alınamadı');
      }
      })
      .catch(error => {
       
        console.error(error);
      });
    } catch (error) {
     console.error("Profil verileri getirilirken hata oluştu: ", error);
    }





    setUserSession(data.token,email);
    const updatedUser = {
      email: email,
      userId:data.userId
    }
    setUserInfo(updatedUser);
    
    
    toast.error(data.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
   
    if (data.claims.includes('admin')) {
       navigate('/adminpanel');
    } else if (data.claims.includes('user')) {
      navigate('/');
    } else {
      navigate('/');
    }
   
  })
  .catch((error) => {
    toast.error(error.message?? "Lütfen Daha Sonra Tekrar Deneyiniz.", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
   
    
  });
  
  
};



return (
  <div style={{ marginTop: "100px" }}>
                <ToastContainer />

    <>
    <h1 style={{ textAlign: "center", fontStyle: "italic" }}>Hoşgeldiniz</h1>

    <form onSubmit={handleSubmit}>
    <div style={{ display: "block", justifyContent: "center", marginTop: "50px", textAlign: "center" }}>
    <div>
    <input
              type="email"
              placeholder="E-posta"
              value={email}
              onChange={handleEmailChange}
              className="input-action"
            />
    </div>
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
    <input
              type="password"
              placeholder="Parola"
              value={password}
              onChange={handlePasswordChange}
              className="input-action"
            />
    </div>
    <div>
    <NavLink style={{ color: "#893694", textDecoration: "none", fontStyle: "italic" }} to='/forgotpassword'>Parolamı Unuttum</NavLink>
    </div>
   
    <div style={{ marginTop:"20px"}}>
    <button type="submit" onClick={handleLoginFormSubmit} className="save-button">Gönder</button>
    </div>
    </div>
    <div style={{ textAlign: "center", display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }}>
    <hr style={{ width: "20rem" }} />
    <div style={{ marginLeft: "10px", marginRight: "10px", fontStyle: "italic", fontWeight: "300" }}>veya</div>
    <hr style={{ width: "20rem" }} />
    </div>
    <p style={{ textAlign: "center", fontStyle: "italic", fontWeight: "300",marginBottom:"50px" }}>Hesabın yok mu?
    <NavLink style={{ color: "#893694", textDecoration: "none", fontStyle: "italic", marginLeft: "20px" }} to='/signup'>Kayıt Ol</NavLink>
    </p>
    </form>
    </>
 
  </div>

);
};

export default WithNavbar(LogIn);
