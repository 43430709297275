import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import { baseUrl } from './config/Constants';

const ImageSlider = ({ images }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <div style={{ width: "300px" }}>
      <Carousel controls={false}>
        {images.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              style={{ padding: "20px", maxWidth: "100%", height: "250px", cursor: "pointer" }}
              src={item}
              alt={item.title}
              onClick={() => handleImageClick(item)}
            />
          
          </Carousel.Item>
        ))}
      </Carousel>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedImage ? selectedImage.title : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && <img src={selectedImage} alt={selectedImage.title} style={{ width: '100%' }} />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageSlider;
