import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { baseUrl } from '../config/Constants';
import { getToken } from "../service/AuthService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AdminOrderList() {
  const [orderList, setOrderList] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [previousSearches, setPreviousSearches] = useState([]);
  const [orderProgress, setOrderProgress] = useState([]);
  const [orderProgressId, setOrderProgressId] = useState({});
  const [progress, setProgress] = useState([]);
  const [progressFilter, setProgressFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const isAllProgressSelected = progressFilter.length === progress.length;

  function showToastError(message, duration = 3000) {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }
  function showToastSuccess(message, duration = 2000) {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }
  const handleProgressFilterChange = (event) => {
    const progressValue = event.target.value;
    let newProgressFilter;
  
    if (progressValue === "") {
      if (progressFilter.length === progress.length) {
        newProgressFilter = [];
      } else {
        newProgressFilter = progress.map((category) => `${category.id}`);
      }
    } else {
      if (progressFilter.includes(progressValue)) {
        newProgressFilter = progressFilter.filter((progrss) => progrss !== progressValue);
      } else {
        newProgressFilter = [...progressFilter, progressValue];
      }
    }

    const filteredOrders = orderList.filter((order) => {
      const orderProgressId = order.orderProgressId.toString();
      return newProgressFilter.includes(orderProgressId);
    });
  
    setFilteredOrders(filteredOrders);
  
    setProgressFilter(newProgressFilter);
  };

  let token = getToken();

  const fetchOrderProgress = async () => {
    try {
      
       await fetch(baseUrl+`api/OrderProgress/GetAllOrderProgress`)
       .then(response => response.json())
       .then(data => {  
        if(data.success)
        {if(data.data.length>0)
            setOrderProgress(data.data)
            setProgress(data.data);
        }
       // console.log(data.data);        
       })
       .catch(error => {
        // console.error(error);
       });      
  }
  catch (error) {
   // console.error(error)
  }
};

  const fetchOrderList = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await fetch(baseUrl+`api/Order/GetAllOrders`, requestOptions);
      const data = await response.json();
      const orderData= data.data;
      setOrderList(orderData);  
      setFilteredOrders(orderData);
    } catch (error) {
     // console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchOrderProgress();  
      fetchOrderList();
    };  
    fetchData();
  }, []);
  

  const handleOrderClick = (OrderDetailId) => {
    if (expandedOrderId === OrderDetailId) {
    setExpandedOrderId(null);
    } else {
    setExpandedOrderId(OrderDetailId);
    }
  };
  
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const search = (event) => {
    event.preventDefault();
    const searchTerm = event.target.elements.searchTerm.value;
    if (searchTerm) {
      setPreviousSearches([...previousSearches, searchTerm]);
      event.target.elements.searchTerm.value = "";
    }
  };

  const handleUpdateOrderProgress = async (order,orderProgressId) =>
  {  
    if(!orderProgressId.order)
    {
      showToastError("Lütfen bir seçim yapınız");
      return null;
    }

    if(order.orderId !== orderProgressId.order.orderId && order.orderDetailId!== orderProgressId.order.orderDetailId)
    {
         showToastError("Lütfen bir seçim yapınız");
         return null;
    }

    const orderDetailProgressData = {
      orderId: order.orderId,
      orderDetailId: order.orderDetailId,
      orderProgressId: orderProgressId.orderProgressId,
    };
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(orderDetailProgressData),
    };

    await fetch(baseUrl + `api/Order/UpdateProgressOfOrder`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        fetchOrderList();
          showToastSuccess('Değişiklikler başarıyla kaydedilmiştir.');
      } else {
        showToastError(data.message ?? "Güncelleme başarısız.");
      }

    })
    .catch((error) => {
      showToastError("Bir hata ile karşılaşıldı.");
    });
    } 
  
  const handleCheckboxClick = (progressId,order) => {
    setOrderProgressId({orderProgressId:progressId === orderProgressId ? null : progressId,order:order});
  };

  const onClose = () => {
    setIsOpen(false);
  };

return (
  <div  style={{ margin: "50px" }}>
            <ToastContainer />
   <h1  className='baslik'>Mon Jardin</h1>
        {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}
        <div className="dropdown" style={{float:"right"}}>
      <button type="button" className='mobile-search-filter' data-bs-toggle="dropdown"style={{background:"transparent",display:"flex",height:"50px"}}>
        <label className="kategori" htmlFor="priceFilter"style={{color:"black"}}>Filtrele</label>
        <div style={{ cursor: "pointer" }}>
        <img
          src={"/images/downarrow.png"} 
          alt="Arrow Icon"
          style={{ width: "15px", height: "8px",marginLeft:"25px",marginTop:"5px" }} 
        />
      </div> 
      </button>
      <ul className="dropdown-menu" style={{maxHeight: "450px",overflowX: "hidden",border:"none",boxShadow: "0 6px 20px rgba(56, 125, 255, 0.17)",width:"210px",borderRadius:"0px"}}>
          <li>
          <label style={{display:"block", fontFamily:"Times New Roman", fontStyle:"italic", fontSize:"18px"}}>
          <input type="checkbox" 
          id="all" 
          name="all" 
          value="" 
          checked={isAllProgressSelected} 
          onChange={handleProgressFilterChange} 
          style={{marginRight:"5px"}}/>
          Tümü
        </label>
        <hr/>
          </li>
        {progress.map((progress) => (
      <li key={progress.id}>
        <label style={{display:"block", fontFamily:"Times New Roman", fontStyle:"italic", fontSize:"18px"}}>
          <input type="checkbox" 
            id={progress.id} 
            name="progress"
            value={progress.id}  
            onChange={handleProgressFilterChange} 
            checked={progressFilter.includes(`${progress.id}`)}
            style={{marginRight:"5px"}} 
          />
          {progress.name}
        </label>
        <hr key={`hr-${progress.id}`} />
      </li>
    ))}

      </ul>
    </div>
    <div style={{marginTop:"50px"}}>
      {orderList && (
      <table className='table table-light'>
      <thead>
      </thead>
      <tbody>
        {filteredOrders.map((order) => (
        
          <React.Fragment key={order.orderDetailId}>
          
            <tr onClick={() => handleOrderClick(order.orderDetailId)} style={{ borderBottom: "1px solid #ccc"}}>
            
              <td> <img src={order.fileUrl} alt={orderList.fileUrl} width={50} height={60} /></td>
              <td style={{verticalAlign:"middle",fontWeight:"400"}}>#{order.orderNumber}</td>
              <td style={{verticalAlign:"middle",fontWeight:"400"}}>{order.productName}</td>
              <td style={{verticalAlign:"middle",fontWeight:"400"}}>{order.total} Adet</td>
              <td style={{verticalAlign:"middle",fontWeight:"400"}}>{order.districtName}</td>        
              <td style={{verticalAlign:"middle",fontWeight:"400"}}>{order.categoryName}</td>
              <td style={{verticalAlign:"middle",fontWeight:"400"}}>{new Date(order.shipmentDate).toISOString().split('T')[0]}</td> 
              
              <td style={{verticalAlign:"middle"}}> <img src="/images/openDetail.png" alt="" width={28} height={20} /></td>
            
            </tr>
            {expandedOrderId === order.orderDetailId && (
              <tr>
                <td colSpan="6">
                  <div style={{display:"flex",marginLeft:"50px"}}>
          
                    <div style={{display:"flex",marginTop:"10px",marginBottom:"20px"}}>
                <div style={{display:"block", fontStyle:"italic", fontFamily:"Times New Roman", marginRight:"80px",fontSize:"18px"}}> <p> {filteredOrders.find((p) => p.orderDetailId === expandedOrderId).productName}</p></div>
                <div style={{display:"block", fontStyle:"italic", fontFamily:"Times New Roman", marginRight:"50px",fontSize:"18px"}}> <p> {filteredOrders.find((p) => p.orderDetailId === expandedOrderId).total} Adet</p></div>
                <div style={{display:"block", fontStyle:"italic", fontFamily:"Times New Roman", marginRight:"50px",fontSize:"18px"}}> <p> {filteredOrders.find((p) => p.orderDetailId === expandedOrderId).amount} TL</p></div>
                <div style={{display:"block", fontStyle:"italic", fontFamily:"Times New Roman", marginRight:"30px",fontSize:"18px"}}> <p> {new Date(filteredOrders.find((p) => p.orderDetailId === expandedOrderId).shipmentDate).toISOString().split('T')[0]}  (Teslimat Tarihi)</p></div>
                <div><button style={{float:"right",marginLeft: "100px",borderRadius:"10px",height:"38px"}} onClick={() => setIsOpen(true)}>Kişi Bilgileri</button></div>

                    </div>
                  </div>


                  {orderProgress.map((progress, progressIndex) => (
              <React.Fragment key={`progress-${progress.id}-${order.id}`}>
                      <label
                        style={{
                          fontStyle: 'italic',
                          fontFamily: 'Times New Roman',
                          marginLeft: '80px',
                        }}
                      >
                        <input
                          type="radio"
                          name="progress"
                          style={{
                            width: '18px',
                            height: '16px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                            marginRight: '0.5rem',
                            fontStyle: 'italic',
                            fontFamily: 'Times New Roman',
                          }}
                          onChange={() => handleCheckboxClick(progress.id,order)}
                          defaultChecked={order.orderProgressId === progress.id}
                        />
                        {progress.name}
                      </label>
                    </React.Fragment>
                  
                ))}
                      <button style={{float:"right",marginRight:"20%",borderRadius:"10px",height:"38px",width:"10%"}} onClick={() => handleUpdateOrderProgress(order,orderProgressId)}>Kaydet</button>
                </td>

                {isOpen && (
                      <div className="order-detail-popup-container">
                      <div className="order-detail-popup" style={{ width: "400px", padding: "20px" }}>
                        <span className="orderdetail-popup-close" onClick={onClose}>
                          &times;
                        </span>
                        <h6 style={{color:"#893694"}}>Teslim Alacak Kişi</h6>

                        <div style={{ display: "flex", marginTop: "25px"}}>
                          <span style={{ borderBottom: "1px solid", fontFamily: "Times New Roman", fontStyle: "italic", marginRight: "45px" }}>{filteredOrders.find((p) => p.orderDetailId === expandedOrderId).addressNameSurname}</span>
                          <span style={{ borderBottom: "1px solid", fontFamily: "Times New Roman", fontStyle: "italic" }}>{filteredOrders.find((p) => p.orderDetailId === expandedOrderId).addressPhoneNumber}</span>
                        </div>


                        <div style={{ display: "flex", marginTop: "25px"}}>
                          
                          <span style={{borderBottom:"1px solid",fontFamily:"Times New Roman", fontStyle:"italic", marginRight: "45px"}}>                    
                           {filteredOrders.find((p) => p.orderDetailId === expandedOrderId).address} - {filteredOrders.find((p) => p.orderDetailId === expandedOrderId).quarterName}/ {filteredOrders.find((p) => p.orderDetailId === expandedOrderId).districtName}/ İzmir
                          </span>  
                            <span style={{borderBottom:"1px solid",fontFamily:"Times New Roman", fontStyle:"italic"}}>{filteredOrders.find((p) => p.orderDetailId === expandedOrderId).cardNote}</span>
                        </div>

                        <h6 style={{color:"#893694",marginTop: "50px"}}>Sipariş Veren Kişi</h6>

                        <div style={{ display: "flex", marginTop: "25px",marginBottom:"50px"}}>
                          <span style={{ borderBottom: "1px solid", fontFamily: "Times New Roman", fontStyle: "italic", marginRight: "45px" }}>{filteredOrders.find((p) => p.orderDetailId === expandedOrderId).userNameSurname}</span>
                          <span style={{ borderBottom: "1px solid", fontFamily: "Times New Roman", fontStyle: "italic" }}>{filteredOrders.find((p) => p.orderDetailId === expandedOrderId).userPhoneNumber}</span>
                        </div>

                      </div>
                    </div>
                      )}
              </tr>
            )}
            
          </React.Fragment>
        ))}
        
      </tbody>
    
    </table>
      )}
    </div>
</div>
)}