import React, { useState,useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { baseUrl } from '../config/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WithNavbar from '../WithNavbar'; 
import { getToken, getUserInfo } from "../service/AuthService";

const MyOrderDetail = () => {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState([]);
    const [orderProgress, setOrderProgress] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
    const [selectedOrderNumber, setSelectedOrderNumber] = useState([]);
    const [score, setScore] = useState(0);

    let token= getToken();

    const onClose = () => {
      setScore(0);
      setSelectedOrderDetails(null);
      setIsOpen(false);
    };

    const fetchOrderDetail = async (id) => {
        try {
          const requestOptions = {
            headers: {
              'Content-Type': 'application/json',
            }
          };
           await fetch(baseUrl+`api/OrderDetail/GetOrderDetailByOrderId?orderId=${id}`,requestOptions)
           .then(response => response.json())
           .then(data => {  
            if(data.success)
            {
                setOrderDetails(data.data)
            }
          //  console.log(data.data);        
           })
           .catch(error => {
          //   console.error(error);
           });      
      }
      catch (error) {
      //  console.error(error)
      }
    };

    const fetchOrderProgress = async () => {
        try {
          
           await fetch(baseUrl+`api/OrderProgress/GetAllOrderProgress`)
           .then(response => response.json())
           .then(data => {  
            if(data.success)
            {if(data.data.length>0)
                setOrderProgress(data.data)
            }
           // console.log(data.data);        
           })
           .catch(error => {
            // console.error(error);
           });      
      }
      catch (error) {
       // console.error(error)
      }
    };

    useEffect(() => {
        const fetchData = async () => {
          await fetchOrderProgress();  
          await fetchOrderDetail(id); 
        };
      
        fetchData();
      }, []);
      const openOrderPopup = async (orderDetail,orderNumber) => {
       setSelectedOrderDetails(orderDetail);
       setSelectedOrderNumber(orderNumber);
        setIsOpen(true);
      };
      
      const createPoint = async (OrderDetail,score) => {
        if(score===0){
          toast.error('Lütfen Puan Veriniz.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        console.log(OrderDetail);
        const token = getToken();
        let userInfo= getUserInfo();
        let userID= userInfo?.userId;
    fetch(baseUrl + "api/Point/CreatePoint", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
  
      body: JSON.stringify({userId:userID,score:score,productId:OrderDetail.productId,orderDetailId:OrderDetail.orderDetailId})
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.success) {
          setScore (0);
          fetchOrderDetail(id); 
          setIsOpen(false);
          selectedOrderDetails(null);

          toast.success('Değişiklikler başarıyla kaydedilmiştir.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });

       }
      else{
        setScore(0);
        selectedOrderDetails(null);
        setIsOpen(false);
        toast.error('Daha sonra tekrar deneyiniz.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }}) 
      .catch((error) => {
        setScore(0);
        setIsOpen(false);
        selectedOrderDetails(null);
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,     
      });
      }
      )}
    

      const styles = {
        cardContainer: {
          display: "flex",
          flexWrap: "wrap", 
          alignItems: "flex-start",
          marginBottom: "30px",
        },
        card: {
          flexBasis: "calc(50% - 80px)",
          margin: "15px",
          background: "white",
          color: "black",
          borderRadius: "12px",
          border: "1px solid #D9D9D9",
          marginLeft:"3%",
        },
        cardContent: {
          padding: "20px",
          textAlign: "left",
          fontStyle: "italic",
          fontSize: "13px",
        },
      };
      
      const handleDivClick = async (id) => {
      };

      const handleStarClick = (index) => {
        setScore (index+1);
        setRating(index + 1);
      };

return (
    <div style={{ marginTop: "120px" }}>
    <div style={styles.cardContainer}>
      {orderDetails?.orderDetail?.map((item, index) => (
        <div style={styles.card} className="order-detail-card" key={item.orderDetailId}>
          <div style={{ ...styles.cardContent, padding: "20px" }}>        
            <p>
              <span style={{ fontWeight: "500", fontSize: "18px", marginTop: "25px" }}>Sipariş No:</span>  <span style={{ fontSize: "16px" }}>#{orderDetails.orderNumber}</span>
              {item.orderProgressId === orderProgress[3].id && (
          <button onClick={() => openOrderPopup(item,orderDetails.orderNumber)} className="point-button" style={{ float: "right" }}>
            Siparişinizi Değerlendiriniz
          </button>
        )}   
            </p>
            {isOpen && (
                    <div className="order-point-popup-container">
                      <div className="order-point-popup" style={{ width: "400px", padding: "20px" }}>
                      <span className="orderdetail-popup-close" onClick={onClose}>
                          &times;
                      </span>
                   <span style={{ fontWeight: "500", fontSize: "18px" }}>Sipariş No: </span>  <span style={{ fontSize: "16px" }}>#{selectedOrderNumber}</span>
                <table className="table table-light">
              <thead></thead>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "middle", padding: 0, width: "100px",textAlign:"center" }}>
                      <img
                        src={
                        selectedOrderDetails?.productFileUrl ||
                          "images/monjardinlogo.png"
                        }
                        style={{ width: "40px", height: "45px" }}
                      />
                    </td>
                    <td
                      style={{ fontStyle: "italic", fontWeight: "bold", verticalAlign: "middle", padding: 0, width: "150px" }}
                    >
                      {selectedOrderDetails.name}
                    </td>
                    <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0, width: "150px",textAlign:"center"}}>{selectedOrderDetails.totalOfOrder} Adet</td>
                    <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0, width: "150px",textAlign:"center" }}>
                      {selectedOrderDetails.amountOfOrder} TL
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5" style={{ border: "none", padding: 0 }}>
                    </td>
                  </tr>
                </tbody>
              </table>    
              <hr/>
                <span style={{fontSize:"15px",fontWeight:"500",marginLeft:"7px"}}>Teslimat Adresi</span> 
                <p style={{ fontWeight: "500", fontSize: "14px",marginLeft:"10px" }}>{selectedOrderDetails.userAddressDetail.addressTitle}</p>
                <p style={{ fontSize: "14px", marginLeft:"10px" }}>{selectedOrderDetails.userAddressDetail.address}</p> 
                <hr/>  
                {!selectedOrderDetails.anyPoint ? Array.from({ length: 5 }, (_, index) => (
                  <img
                    key={`star-${index}`}
                    src={index < score ? "/images/fullflowericon.png" : "/images/emptyflowericon.png"}
                    width={23}
                    height={23}
                    style={{ marginLeft: "10px", cursor: 'pointer' }}
                    onClick={() => handleStarClick(index)}
                  />
                )):
                Array.from({ length: 5 }, (_, index) => ( <img
                    key={`star-${index}`}
                    src={index < selectedOrderDetails.point ? "/images/fullflowericon.png" : "/images/emptyflowericon.png"}
                    width={23}
                    height={23}
                    style={{ marginLeft: "10px" }}
                  />))       
              }
                { !selectedOrderDetails.anyPoint && 
                <button onClick={()=>createPoint(selectedOrderDetails,score)} className="save-button" style={{float:"right", marginTop:"30px"}}>Gönder</button>
                }
              </div>
            </div>
            )}
            <table className="table table-light">
              <thead></thead>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "middle", padding: 0, width: "100px",textAlign:"center" }}>
                      <img
                        src={
                        item?.productFileUrl ||
                          "images/monjardinlogo.png"
                        }
                        style={{ width: "40px", height: "45px" }}
                      />
                    </td>
                    <td
                      style={{ fontStyle: "italic", fontWeight: "bold", verticalAlign: "middle", padding: 0, width: "150px" }}
                    >
                      {item.name}
                    </td>
                    <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0, width: "150px",textAlign:"center"}}>{item.totalOfOrder} Adet</td>
                    <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0, width: "150px",textAlign:"center" }}>
                      {item.amountOfOrder} TL
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5" style={{ border: "none", padding: 0 }}>
                    </td>
                  </tr>
                </tbody>
            </table>
            <div className="timeline">
              {orderProgress.map((progress, progressIndex) => (
                <React.Fragment key={`progress-${progress.id}`}>
                  {progressIndex > 0 && <div className="timeline-line" key={`line-${progress.id}`}></div>}
                  <div
                    className={`timeline-item ${item.orderProgressId >= progress.id ? "completed" : ""}`}
                    key={`item-${progress.id}`}
                  >
                    <div className="timeline-circle"></div>
                    <div className="timeline-content">{progress.name}</div>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <p style={{ fontWeight: "600", fontSize: "16px" }}>Teslimat Adresi</p>
            <p style={{ fontWeight: "500", fontSize: "15px",marginLeft:"7px" }}>{item.userAddressDetail.addressTitle}</p>
            <p style={{ fontSize: "15px", marginLeft:"7px" }}>{item.userAddressDetail.address}</p>
          </div>
        </div>
      ))}
    </div>
  </div>

);
};

export default WithNavbar(MyOrderDetail);

