import React, { useState,useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { getToken, setUserSession,setUserInfo,getUserInfo } from "../service/AuthService";
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl,campaignDocumentId } from '../config/Constants';
import WithNavbar from '../WithNavbar'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useParams } from 'react-router-dom';
import { getFirestore, collection, getDocs, doc,setDoc ,query,where,getDoc} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';


const OrderResult = () => {
    const {success, orderId } = useParams();
    const [message, setMessage] = useState([]);
    const [orderNumber, setOrderNumber] = useState([]);
    const [earnedCampaign, setEarnedCampaign] = useState(0);

    const navigate = useNavigate();
    const userInfo = getUserInfo(); 
    const userID = userInfo?.userId;
    const firebaseConfig = {
      apiKey: "AIzaSyBVljeCIm_rhZBx0522TXkNa4G4ufKoMLY",
      authDomain: "monjardin-7cc13.firebaseapp.com",
      projectId: "monjardin-7cc13",
      storageBucket: "monjardin-7cc13.appspot.com",
      messagingSenderId: "81286471664",
      appId: "1:81286471664:web:51a5c1de2c61b4551b4735",
      measurementId: "G-5BW4TRMJDR"
    }; 
  
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);

 //  const totalPrice = window.totalPrice;
  //  const activeTotalAmountEarned = window.activeTotalAmountEarned;
    

  const totalPrice = localStorage.getItem('totalPricex');
  const activeTotalAmountEarned = localStorage.getItem('activeTotalAmountEarnedx');

    useEffect(() => {   
        const fetchData = async () => {
          await fetchOrderResult( activeTotalAmountEarned, totalPrice);
        };
          fetchData();
         
    }, [success, orderId]);

    const updateUserTotalAmountEarned = async (userId, activeTotalAmountEarned,newAmountEarned) => {
      console.log("Function called with new total: " + activeTotalAmountEarned + newAmountEarned);
    
      const firestore = getFirestore();
      const usersCollection = collection(firestore, 'users');
    
      const userQuery = query(usersCollection, where('userId', '==', userId));
      const usersSnapshot = await getDocs(userQuery);
    
      for await (const userDoc of usersSnapshot.docs) {
        try {
          const userDocRef = doc(firestore, 'users', userDoc.id);
          const currentTotalAmountEarned = userDoc.data().totalAmountEarned || 0;
          const newTotalAmountEarned = currentTotalAmountEarned -activeTotalAmountEarned + newAmountEarned;
          await setDoc(userDocRef, { totalAmountEarned: newTotalAmountEarned }, { merge: true });
          
          console.log('TotalAmountEarned updated successfully.');
        } catch (error) {
          console.error('Error updating TotalAmountEarned:', error);
        }
      }
      
    };
    
    const handleGetAfterOrderEarnedAmount = async (documentId, totalPrice,activeTotalAmountEarned) => {
      try {
        const docRef = doc(firestore, 'campaign', documentId);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
         
          const campaigns = docSnap.data().campaigns;
        
          campaigns.sort((a, b) => b.minimumShippingAmount - a.minimumShippingAmount);
        
          let earnedAmount = null;
        
          for (const campaign of campaigns) {
            const { minimumShippingAmount, discountAmount } = campaign;
        
            if (totalPrice >= minimumShippingAmount) {
             
              earnedAmount = discountAmount;
              return earnedAmount;
             
            }
          }
          if (earnedAmount !== null) {
           // console.log(`For totalPrice ${totalPrice}, selected discountAmount is ${earnedAmount}`);
            
          } else {
            //console.log(`No matching discountAmount found for totalPrice ${totalPrice}`);
            return 0;
          }
        } else {
          //console.log("No such document!");
          
        }
        
      } catch (error) {
        console.error("Error getting document:", error);
        return 0;
      }
    };
  
    const fetchOrderResult = async (activeTotalAmountEarned, totalPrice) => {
        try {
          await fetch(baseUrl + `api/Order/GetOrderResult/`+success+"/"+orderId)
          .then(response => response.json())
          .then(async(data) => {  
            if(data.success)
            {
              // 3d başarılı olursa güncelleeeeeee altındkaileri deeee
              if(activeTotalAmountEarned > 0){
                await updateUserTotalAmountEarned(userID,activeTotalAmountEarned,0);
              }
              else{
                const earnedAmount = await handleGetAfterOrderEarnedAmount(campaignDocumentId, totalPrice);
                setEarnedCampaign(earnedAmount);
                await updateUserTotalAmountEarned(userID,activeTotalAmountEarned,earnedAmount);
              }
              setOrderNumber(data.data);
            }
            else{
             setMessage(data.message??"Bilinmeyen bir hata ile karşılaşıldı");
            }
           });      
      }
      catch (error) { 
        setMessage("Bilinmeyen bir hata ile karşılaşıldı");
      }
    
    };

    const goHomePage = () => {
        navigate('/');
    };

return (
  <div style={{ marginTop: "100px" }}>
    <ToastContainer />
    <>
    <div style={{textAlign:"center",marginBottom:"10%"}}>
        {success=="True" &&
            <>
            <img src={"/images/succesorder.png"} alt="" width="50%"/>
            <p className="address-detail-box-text" style={{marginTop:"25px"}}><span style={{fontStyle:"italic"}}>#{orderNumber} sipariş numaranızdan kontrol edebilirsiniz. </span> </p>
            <div style={{position: "relative"}}>
            <img src={"/images/campaign.png"} alt="" width="50%" />
            <p style={{position: "absolute", top: "20%", left: "50%", transform: "translate(-50%, -50%)", color: "black", fontSize: "25px", fontStyle:"italic", fontFamily:"Times New Roman"}}>Tebrikler!</p>
            <p style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", fontSize: "20px", fontFamily:"Times New Roman"}}>{earnedCampaign} TL Kazandınız!</p>
          </div>
            </>
        }

        {success=="False" &&
        <>
         <img src={"/images/close.png"} alt="" width="3%"/>
         <img src={"/images/noorder.png"} alt="" width="45%" style={{marginTop:"1%",marginLeft:"2%"}}/>
         <p className="address-detail-box-text" style={{marginTop:"25px"}}><span style={{fontStyle:"italic"}}>{message} </span> </p>
        </>
        }
       
        <button className="basket-no-product-button" onClick={goHomePage}>
            Alışverişe devam et
        </button>
    </div>
    </>
 
  </div>

);
};

export default WithNavbar(OrderResult);

