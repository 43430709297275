import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
//import Admincard from './Admincard';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getUser,getToken,resetUserSession,getUserInfo } from "../service/AuthService";
import { baseUrl } from '../config/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddShipment() {
  const navigate = useNavigate();
  const token = getToken();
  const [district, setDistrict] = useState([]);
  const [quarter, setQuarter] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [shipment, setShipment] = useState("");

  const fetchDistricts = async () => {
    try {
      const response = await fetch(baseUrl+`api/Quarter/GetQuartersByDistrictGroup`);
      if (!response.ok) {
        throw new Error('Bölgeler listesi getirilemedi.');
      }
      const data = await response.json();
    
      const districtData= data.data;
  
      setDistrict(districtData);
    
    } catch (error) {
      
    //  console.error("Bölgeler getirlirken hatayla karşılaşıldı.");
    }
};

function showToastError(message, duration = 3000) {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
}


function showToastSuccess(message, duration = 2000) {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
}

const handleKaydet =async () => {

  const addShipment = {
    ...shipment,
    districtId: parseInt(selectedDistrict),
    quarterId: parseInt(selectedQuarter),
    sameDayShipping: true
  };

  const requiredFields = ['shippingFee', 'districtId', 'quarterId'];

  const isNonShipmentEmpty = requiredFields.some((field) => {
    const value = addShipment[field];
    return value === undefined || value === null || value === '' || (typeof value === 'number' && isNaN(value));
  });

  if (isNonShipmentEmpty) {
    showToastError('Lütfen tüm alanları doldurunuz.');
    return;
  }
const token = getToken();
fetch(baseUrl + "api/Shipment/CreateShipment", {
  method: "POST",
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(addShipment),
})
  .then((response) => response.json())
  .then((data) => {
    if(data.success)
    {
      showToastSuccess('Değişiklikler başarıyla kaydedilmiştir.');
      setTimeout(() => {
        navigate("/adminshipmentlist");
      }, 2000);
    }
    else{
      toast.error(data.message??"Bilinmeyen bir hata ile karşılaşıldı", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    
  })
  .catch((error) => {
    toast.error(error.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  });
};

const handleInputChange = (e) => {
  setShipment({
    ...shipment,
    [e.target.name]: e.target.value,
  });
};

const handleSelectDistrict = (event) => {
    const selectedDistrict = event.target.value;
      setSelectedDistrict(selectedDistrict);
        if(parseInt(selectedDistrict)>0)
        {
          const selectedDistrictObject = district.find((district) => district.districtId === parseInt(selectedDistrict));
          setQuarter(selectedDistrictObject.quarterResponseModels);
          setSelectedQuarter('');
        }
         else{
          setSelectedQuarter('');
          setQuarter([]);
        }
};

const handleSelectQuarterChange = (event) => {
    const selectedQuarter = event.target.value;
    setSelectedQuarter(selectedQuarter);
  };

  
  const handleGoBack = () => {
    navigate(-1); // Bir önceki sayfaya yönlendirir
  };

  useEffect(() => 
  {
    fetchDistricts();
  },[]);
return (
    <div style={{ margin: "50px" }}> 
            <ToastContainer />
    <h1  className='baslik'>Mon Jardin</h1>
        <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button>
    <div style={{marginLeft:"10%"}}>

    <select
        name="districtId"
        value={selectedDistrict}
        onChange={handleSelectDistrict}
        className="add-shipment-form" 
        >
        <option value="">İlçe Seçiniz</option>
            {district.length === 0 ? (
            <option disabled>İçe bulunamadı.</option>
            ) : (
            district.map(d => (
                <option key={d.districtId} value={d.districtId}>
                {d.districtName}
                </option>
            ))
            )}
        </select>

        <select
        name="quarterId"
        value={selectedQuarter}
        onChange={handleSelectQuarterChange}
        className="add-shipment-form"
        >
          <option value="">Mahalle Seçiniz</option>
              {quarter.length === 0 ? (
              <option disabled>Mahalle bulunamadı.</option>
              ) : (
              quarter.map(q => (
                  <option key={q.id} value={q.id}>
                  {q.name}
                  </option>
              )))}
        </select> 
        <div style={{alignItems: "center", position: "relative"}}>
          <input
            type="number"
            name="shippingFee"
            onWheel={(e) => e.preventDefault()}
            value={shipment.shippingFee}
            onChange={handleInputChange}
            className="add-shipment-form"
            placeholder="Kargo Ücreti Giriniz"
            style={{flex: "1", border: "none", borderBottom: "1px solid black", paddingRight: "50px",width:"80%"}}
          />
          <span style={{position: "absolute", top: "50%", right: "25%", transform: "translateY(-25%)", borderLeft: "1px solid black", paddingLeft: "5px",height:"68%",fontSize:"20px"}}>
            <span style={{marginLeft:"80px"}}>TL</span>
          </span>
        </div>
        <button className="save-button" style={{float:"right", marginTop:"30px",marginRight:"20%"}} onClick={handleKaydet}>Kaydet</button>

    </div>
    </div>
    );
  } 

  




export default AddShipment;