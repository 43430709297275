import React, { useEffect,useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {useNavigate} from "react-router-dom"
import { getToken, getUserInfo,resetUserSession } from "./service/AuthService";

function getItem(link,label, key, children, type) {
  return {
    link,
    key,
    children,
    label,
    type,
  };
}
const items = [
    getItem('/adminpanel',"Anasayfa", '1'),
    getItem('/AdminProductList',"Stok Kontrol", '2'),
    getItem('/AdminSearch',"Kalemler", '3'),
    getItem('/AdminAddProduct',"Yeni Kalem Ekle", '4'),
    getItem('/EditCategory',"Kategori Düzenleme", '5'),
    getItem('/adminallblog',"Blog Düzenleme", '6'),
    getItem('/adminallnotification',"Bildirimler", '7'),
    getItem('/EditSlider',"Duyuru", '8'),
    getItem('/adminorderlist',"Siparişler", '9'),
    getItem('/adminshipmentlist',"Kargo Düzenle", '10'),
    getItem('/admincampaignlist',"Kampanya Düzenle", '11'),
    getItem('/adminsmslist',"Sms", '12'),


];

const AdminMenu = () => {
  const navigate=useNavigate();
  const signout=()=>{
    resetUserSession();
    navigate("/");
  }

  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedKey, setSelectedKey] = useState(''); //1 
  const location = useLocation();

 
  useEffect(() => {
    const pathname = location.pathname;
    const menuItem = items.find(item => item.link === pathname);
    if (menuItem) {
      setSelectedKey(menuItem.key);
    }
  }, [location.pathname]);

  const handleMenuClick = (key) => {
    console.log("key",key)
    setSelectedKey(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
       
      } catch (error) {
       // console.error('Error fetching user data:', error.message);
      } finally {
        // console.error('User not found in Firestore');
      }
    };

    fetchData();
  }, []);



  const renderMenuItems = (menuItems, isSubmenu = false) => {
    return menuItems.map((item, index) => {
     
      return (
        <Menu.Item
          key={item.key}
          style={{
            textAlign: 'left',
            borderBottom: '5px',
            borderRadius: '0px',
            height: '50px',
            textDecoration:"none"
          }}
        >
          <Link style={{textDecoration:"none",color:"white",fontStyle:"italic", fontFamily:"Times New Roman", fontSize:"17px"}} to={item.link}>{item.label}</Link>
        </Menu.Item>
      );
    });
  };
  
  const user=getUserInfo();

  return (
    <div
      style={{
        width: "250px",
        overflowY: 'auto',
        height:"100%"
      }}
    >
      <Menu
         selectedKeys={[selectedKey]}
        mode="inline"
        theme="dark"
        style={{background:"#893694" ,  height:"%100", overflowY: 'auto'}}
        className='admin-menu'
        onClick={({ key }) => handleMenuClick(key)} 
      >
          <Menu.Item key={"icon"} style={{height:"150px", pointerEvents: "none",marginBottom: "-20px",marginTop:"25px"}}>
           
          {user && (
            <div className="text-center">
                <img src="/images/person.png" alt="" width={"45"} height={"45"} />
                <p  style={{textDecoration:"none",color:"white", fontFamily:"Times New Roman", fontSize:"17px"}}>{user.firstName}</p>
                <p  style={{textDecoration:"none",color:"white", fontFamily:"Times New Roman", fontSize:"17px"}}>{user.email}</p>
            </div>
            )}

          </Menu.Item>
         
          {renderMenuItems(filteredItems)}
         
          <Menu.Item key={"signout"} style={{textAlign:"left"}} onClick={()=>{signout()}}>
            <span style={{color:"white",fontStyle:"italic", fontFamily:"Times New Roman", fontSize:"17px"}}>Çıkış Yap</span>
          </Menu.Item>
      </Menu>
    </div>
  );
};
export default AdminMenu;
