import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WithNavbar from '../WithNavbar'; 
import {getEmail, getToken, getUserInfo, setUserInfo,resetUserSession} from "../service/AuthService";
import { baseUrl,campaignDocumentId } from '../config/Constants';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, doc,setDoc ,query,where,getDoc} from 'firebase/firestore';
import { initializeApp } from 'firebase/app';


const Order = () => {
  /* 
  // monjaridntest
  const firebaseConfig = {
    apiKey: "AIzaSyBqg2nJJj4pxevSA7I_N2fj36-R5Jhvqbo",
    authDomain: "monjardintest-1bd6a.firebaseapp.com",
    projectId: "monjardintest-1bd6a",
    storageBucket: "monjardintest-1bd6a.appspot.com",
    messagingSenderId: "970382110934",
    appId: "1:970382110934:web:944389b07eff9f9b3d5977"
  };*/

  const firebaseConfig = {
    apiKey: "AIzaSyBVljeCIm_rhZBx0522TXkNa4G4ufKoMLY",
    authDomain: "monjardin-7cc13.firebaseapp.com",
    projectId: "monjardin-7cc13",
    storageBucket: "monjardin-7cc13.appspot.com",
    messagingSenderId: "81286471664",
    appId: "1:81286471664:web:51a5c1de2c61b4551b4735",
    measurementId: "G-5BW4TRMJDR"
  }; 

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const [totalAmountEarned, setTotalAmountEarned] = useState(null);
  const [activeTotalAmountEarned, setActiveTotalAmountEarned] = useState(0);
  const [buttonState, setButtonState] = useState('used'); // 'used' or 'notUsed'

  
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(); 
  const [totalPrice, setTotalPrice] = useState();
  const [expireMonth, setExpireMonth] = useState("01");
  const [expireYear, setExpireYear] = useState("2023");

  // const { items, totalItems, totalPrice } = location.state || { items: [], totalItems: 0, totalPrice: 0 };
  const [addresses, setAddresses] = useState([]); 
  const [selectedAddress, setSelectedAddress] = useState(null); 
  const [errorAddressMessage, setErrorAddressMessage] = useState(null);
  const [checked, setChecked] = useState(false);  
  const [checkedSozlesme, setCheckedSozlesme] = useState(false);

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [taksitler, setTaksitler] = useState([]);
  const [selectedTaksit, setSelectedTaksit] = useState(null); 
  const [selectedTaksitPrice, setSelectedTaksitPrice] = useState(null); 
  const [isAdsressDetailVisible, setIsAdsressDetailVisible] = useState(false);
  const [basketIdList, setBasketIdList] = useState([]);
  const [adresIdList, setAdresIdList] = useState([]);
  const [htmlContent, setHtmlContent] = useState("");

  const token = getToken(); 
  const userInfo = getUserInfo(); 
  const userID = userInfo?.userId; 
  let userEmail= getEmail();


 
  const handleGetDocument = async (documentId, totalPrice) => {
    try {
      const docRef = doc(firestore, 'campaign', documentId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const campaigns = docSnap.data().campaigns;
      
        campaigns.sort((a, b) => b.minimumShippingAmount - a.minimumShippingAmount);
      
        let selectedDiscountAmount = null;
      
        for (const campaign of campaigns) {
          const { minimumShippingAmount, discountAmount } = campaign;
      
          if (totalPrice >= minimumShippingAmount) {
            selectedDiscountAmount = discountAmount;
            break; 
          }
        }
      
        if (selectedDiscountAmount !== null) {
         // console.log(`For totalPrice ${totalPrice}, selected discountAmount is ${selectedDiscountAmount}`);
        } else {
         // console.log(`No matching discountAmount found for totalPrice ${totalPrice}`);
        }
      } else {
        //console.log("No such document!");
      }
      
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const firestore = getFirestore();

      try {
        const usersCollection = collection(firestore, 'users');
        const usersSnapshot = await getDocs(usersCollection);

        usersSnapshot.forEach((doc) => {
          const userData = doc.data();

          if (userData.userId === userID) {
            setTotalAmountEarned(userData.totalAmountEarned);
          }
          
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

 

    fetchData();

  }, []); 


  const navigate = useNavigate();

  const goHomePage = () => {
    navigate('/');
  };


  const handleAddAddress = (userInfo) => {
    navigate('/addaddress', { state: { createorder: true } });
  }
  
  const handleActiveTotalEarned = () => {
    const priceDifference = totalPrice - totalAmountEarned;

    if (totalPrice < totalAmountEarned || priceDifference < 5) {
      toast.error("Ürün fiyatı indirimden düşük olduğu veya fark 5 TL'den küçük olduğu için kupon kullanılamaz!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.error('Ürün fiyatı indirimden düşük olduğu veya fark 5 TL\'den küçük olduğu için işlem yapılamaz!');
      return;
    }
  
    setButtonState((prevState) => (prevState === 'used' ? 'notUsed' : 'used'));
    if (buttonState === 'used') {
      setActiveTotalAmountEarned(totalAmountEarned);
    } else {
      setActiveTotalAmountEarned(null); // veya setActiveTotalAmountEarned(0);
    }
  };
  
  
  
  const  handleBuyClick  = async () => {
     if (!cardName || !cardNumber || !expireMonth || !expireYear || !cvv) {
      toast.error("Lütfen tüm kart bilgilerinizi doldurun.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return; 
    }

    if (selectedAddress === null || selectedAddress === undefined || selectedAddress === 0) {
      toast.error("Lütfen fatura adresi seçiniz.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return; 
    }
    
    if(!checkedSozlesme){
      toast.error("Lütfen sözleşmeyi kabul ediniz.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return; 
    }
    
    if (!selectedTaksit) {
      toast.error("Lütfen bir taksit seçin.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return; 
    }
    
    const totalprice = totalPrice + kargoFiyati ; 
    const totalpricestring = totalprice.toString();

   // exportTotalPrice(totalPrice,activeTotalAmountEarned);

   localStorage.setItem('totalPricex', totalPrice);
    localStorage.setItem('activeTotalAmountEarnedx', activeTotalAmountEarned);
    
    const requestData = {
      price: totalpricestring,
      paidPrice:selectedTaksitPrice,
      installment: selectedTaksit,
      basketIds: basketIdList,
      callbackUrl: "https://www.mjcicek.com/order",  //bu değişcekkkkk
      cardHolderName: cardName,
      cardNumber: cardNumber,
      expireMonth: expireMonth,
      expireYear: expireYear,
      cvc: cvv,
      userId: userID,
      billingAddressId: parseInt(selectedAddress),
      shippingAddressIds: adresIdList,
      paymentChannel: 1 //web olduğu için
    };
    
   
    console.log("requestData: ", JSON.stringify(requestData, null, 2));


    try {
      const token = getToken(); 
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData), 
      };
  
      const response = await fetch(baseUrl+`api/Order/Form3d`, requestOptions);
  
      if (response.ok) {

        const responseData = await response.json(); 

      //  console.log("API Yanıtı:", responseData); 
      //  console.log("API Yanıtı:", responseData.data.htmlContent); 

    
        if (responseData.data && responseData.data.htmlContent) {
        //  console.log("ife girdi");
          setHtmlContent(responseData.data.htmlContent);
      
          document.open();
          document.write(responseData.data.htmlContent);
          document.close();
      }
        
        
         else {
          console.error("API yanıtında beklenen veri yok.");
        }

      } 
      else {
        response.json().then(data => {
          console.error("Hata Mesajı", data);
      
          if (data.data && data.data.errorCode && data.data.errorCode === "14") {
            toast.error("Kartınızın son kullanma tarihi hatalı.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } 
          if (data.data && data.data.errorCode && data.data.errorCode === "15") {
            toast.error("CVC geçersizdir.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } 
          else{
            toast.error(data.data.errorMessage, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        });
      
      }
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu: ", error);
    }
  };

  const fetchBaskets = async (id) => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      await fetch(baseUrl + `api/Basket/BasketOfUser`, requestOptions)
      .then(response => response.json())
      .then(data => {  
        if(data.success) {
          setItems(data.data);
          const newIdList = data.data.map(item => item.id);
          setBasketIdList(newIdList);

          const newAdressIdList = data.data.map(item => item.userAddressId);
          setAdresIdList(newAdressIdList);
        
          let totalItemCount = 0;
          const totalPrice = data.data.reduce((total, item) => {
            totalItemCount += item.total;
            const itemPrice = item.productDetailResponse.price;
            const itemTotal = item.total;
            return total + itemPrice * itemTotal;
          }, 0);
          setTotalItems(totalItemCount);
          setTotalPrice(totalPrice); 
          handleGetDocument(campaignDocumentId, totalPrice);


        }
        else{
         // alert(data.message ?? "Bilinmeyen bir hata ile karşılaşıldı.")
        }
       })
       .catch(error => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        
       });      
  }
  catch (error) { 
    console.error(error)
  }

};
 
  const createOrder = async (orderData) => {
    try {
      const token = getToken(); 
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(orderData), 
      };
  
      const response = await fetch(baseUrl+ `api/Order/CreateOrder`, requestOptions);
  
      if (response.ok) {
        setIsAdsressDetailVisible(true);
      } else {
        response.text().then(errorMessage => {
        });      }
    } catch (error) {
    //  console.error("Sipariş oluşturulurken beklenmeyen bir hata oluştu: ", error);
    }
  };

  const exportTotalPrice = (price,activeTotalAmountEarned) => {
    window.totalPrice = price;
    window.activeTotalAmountEarned = activeTotalAmountEarned;
   
  };


  
  const kargoFiyati = items.reduce((totalShipmentFee, item) => {
    return totalShipmentFee + item.shipmentFee;
  }, 0);

  const sortedItems = items.sort((a, b) => a.productDetailResponse.name.localeCompare(b.productDetailResponse.name));

  useEffect(() => {
    const token = getToken(); 
    const userInfo = getUserInfo(); 
    const userID = userInfo?.userId; 

    const fetchData = async () => {
      await fetchBaskets(); 
    };
    
    const fetchUserAddress = async () => {
      try {
        const requestOptions = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        const response = await fetch(`${baseUrl}api/UserAddress/GetAllUserAddressByUserId/?userId=${userID}`, requestOptions);
        const data = await response.json();

        if (data.data.length === 0) {
          setErrorAddressMessage("Kayıtlı adres bulunamamıştır.");
        } else {
            setAddresses(data.data);
        }
      } catch (error) {
      //  console.error("Adres verileri getirilirken hata oluştu: ", error);
        toast.error('Adres verileri getirilirken hata oluştu');
      }
    };
    fetchData();
    fetchUserAddress();

  }, []);
  const handleClosePopup = () => {
    setIsAdsressDetailVisible(false);
  };


  const getBinAndInstallment = async (cardNumberx) => {
      const toplamFiyat = totalPrice + kargoFiyati-activeTotalAmountEarned;
      //const toplamFiyat = totalPrice + kargoFiyati;

      const toplamFiyatString = toplamFiyat.toString();
  
      const requestData = {
        binNumber: cardNumberx.slice(0, 6),
        price: toplamFiyatString, 
      };
  
      
      try {
        const response = await fetch(baseUrl+`api/Order/BinAndInstallment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(requestData ),
        });
    
        if (response.ok) {
          const data = await response.json();

          const installmentPrices = data.data.details[0].installmentPrices;

          if (installmentPrices && Array.isArray(installmentPrices) && installmentPrices.length > 0) {
              const processedData = installmentPrices.map(item => ({
                installmentNumber: item.installmentNumber,
                  price: item.price,
                  totalPrice:item.totalPrice 
              }));
              setTaksitler(processedData);
          } else {
              console.error("Invalid data structure or empty installmentPrices array.");
          }       
        } else {
          console.error('API Request Failed'+response);
        }
      } catch (error) {
        console.error('Error:', error);
      }
  
  };
  
  
  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
        if (e.target.value.length === 16) {
      getBinAndInstallment(e.target.value);
    }
    else{
     // console.log("kart uzunluğu yeterli değil")
    }
  };
  
  const handleMonthChange = (e) => {
    setExpireMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setExpireYear(e.target.value);
  };

  const handleCvvChange = (e) => {
    const inputText = e.target.value;
      if (/^\d{0,3}$/.test(inputText)) {
      setCvv(inputText);
    }
  };
  
  

  return (
    <div className="mobile-generic-css"  style={{ margin: "5% 10%"}}>
        <ToastContainer />

    <div className='order-responsive-main'>
    <div  className='order-responsive-products' >
  {/* Ürünler */}
    <div>
      {sortedItems.length > 0 ? (
        <table className='table table-light'>
         
         <tbody>
  {sortedItems.map(item => (
    <tr key={item.id}>
      <td>
        <img
          src={item.productDetailResponse.fileResponses[0]?.fileUrl || "images/monjardinlogo.png"}
          alt={item.productDetailResponse.name}
          className="basket-image"
        />
      </td>
      <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0 }}>{item.productDetailResponse.name}</td>
      <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0 }}>{item.total} adet</td>
      <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0 }}>{item.productDetailResponse.price} TL</td>
      <td style={{ fontStyle: "italic", verticalAlign: "middle", padding: 0 }}>+ {item.shipmentFee} TL Kargo</td>
    </tr>
  ))}
    </tbody>
       <tfoot>
  <tr>
    <td className="toplamtext">Ara Toplam:</td>
    <td></td>
    <td style={{ fontStyle: "italic", verticalAlign: "middle", width: "150px", fontSize: "18px" }}>{totalItems} adet</td>
    <td></td>
    <td style={{ fontStyle: "italic", verticalAlign: "middle", width: "150px", fontSize: "18px" }}>{totalPrice} TL</td>
  </tr>
  <tr style={{borderBottom: "1px solid #ccc"}}> 
    <td className="toplamtext" >Kargo:</td>
    <td></td>
    <td></td>
    <td></td>
    <td style={{ fontStyle: "italic", verticalAlign: "middle", width: "150px", fontSize: "18px" }}>{kargoFiyati} TL</td>
  </tr>
  <tr>
    <td className="toplamtext">Toplam: </td>
    <td></td>
    <td style={{ fontStyle: "italic", verticalAlign: "middle", width: "150px", fontSize: "18px" }}>{totalItems} adet</td>
    <td></td>
    <td style={{ fontStyle: "italic", verticalAlign: "middle", width: "150px", fontSize: "18px" }}>{totalPrice + kargoFiyati} TL</td>
  </tr>

    
  {activeTotalAmountEarned ? <tr>
    <td className="toplamtext" style={{ color:"green" }}> Kupon Eklendi </td>
    <td></td>
    <td style={{ fontStyle: "italic", verticalAlign: "middle", width: "150px", fontSize: "18px", color:"green" }}></td>
    <td></td>
    <td style={{ fontStyle: "italic", verticalAlign: "middle", width: "150px", fontSize: "18px", color:"green" }}>{totalPrice + kargoFiyati-totalAmountEarned} TL</td>
  </tr> 
  : 
  <></>
  }
  
  
</tfoot>
</table>
 ) : (
    <p>Sepetinizde ürün bulunmamaktadır.</p>
  )}
    </div>

    {/* Fatura Adresi */}
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",marginBottom:"30px",marginTop:"50px" }}>
        <h3 style={{ textAlign: "left", fontStyle: "italic", margin: 0,  fontFamily:"times",fontWeight:"bold" }}>Kayıtlı Adreslerim</h3>
        <a
          style={{ margin: "0 3px", color: "#893694", fontStyle: "italic", textAlign: "right", cursor: "pointer", fontFamily:"times", fontSize:"18px" }}
          onClick={() => handleAddAddress()}
        >
          Adres Ekle
        </a>
      </div>
    <div style={{ marginTop: '20px', border: '.5px solid black', borderRadius: '5px', padding:"5px" }}>
    <select
        onChange={(e) => setSelectedAddress(e.target.value)}
        value={selectedAddress}
        style={{
        width: '100%',
        height: '40px',
        fontSize: '16px',
        border: 'none', 
        outline: 'none', 
        }}
    >
        <option value={null}>Fatura Adresi Seçiniz</option>
        {addresses.map((address) => (
        <option key={address.userAddressId} value={address.userAddressId}>
        {address.addressTitle}
        </option>
        ))}
    </select>
    {errorAddressMessage && <p className="message">{errorAddressMessage}</p>}
    </div>
    </div>

    <div className='order-responsive-credit-card' >
   
      {/* İndirim */}
      <div>
      <div style={{display:"flex", marginTop:"10px"}}> 
        <div style={{border:"1px solid black",fontWeight:"bold", overflow:"hidden", height:"40px",  fontFamily:"Times New Roman", alignItems: "center",  display: "flex",
          justifyContent: "center",}}>
        {totalAmountEarned} TL indirim bulunmaktadır.
        </div>
        <button 
        onClick={handleActiveTotalEarned}
         style={{
          height: "40px",
          float:"right",   
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 10px",
          backgroundColor: "#893694",
          border: "1px solid gray",
          boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",   
         
        }}>
                {buttonState === 'used' ? 'Kullan' : 'Kullanma'}

 </button>
        
       </div>
       <div style={{color:"#9D9B9B",  fontFamily:"Times New Roman",}}>
        *daha çok alışveriş yapın, daha çok indirim kazanın.
        </div>
       </div>

        {/* Kart Bilgileri */}
        <div style={{ marginTop: "60px" }}>
    <div style={{ color: "#893694", fontWeight: "bold", fontFamily: "times", fontSize: "20px" }}>Kart Bilgileriniz </div>
    <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
        <input
        placeholder="Kart Üzerindeki Ad Soyad"
        type="text"
        value={cardName}
        onChange={(e) => setCardName(e.target.value)}
        style={{ marginLeft: "10px", fontStyle: "italic", color: "black", width: "100%" }}
        />
    </div>
    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <input
        placeholder="Kart Numarası"
        type="text"
        value={cardNumber}
        onChange={handleCardNumberChange} 
        style={{ marginLeft: "10px", fontStyle: "italic", color: "black", width: "100%" }}
        />
    </div>
    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
      
         <div  style={{ marginLeft: "10px", fontStyle: "italic", color: "black", width: "50%" }}>
    
        <select value={expireMonth} onChange={handleMonthChange}>
          <option value="" disabled>Ay</option>
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>          
        </select>
      </div>
      <div>/</div>
      <div  style={{ marginLeft: "10px", fontStyle: "italic", color: "black", width: "50%" }}>
        <select value={expireYear} onChange={handleYearChange}>
          <option value="" disabled>Yıl</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
          <option value="2027">2027</option>
          <option value="2028">2028</option>
          <option value="2029">2029</option>
          <option value="2030">2030</option>
          <option value="2031">2031</option>
          <option value="2032">2032</option>
          <option value="2033">2033</option>
          <option value="2034">2034</option>
          <option value="2035">2035</option>
          <option value="2036">2036</option>
          <option value="2037">2037</option>
          <option value="2038">2038</option>
          <option value="2039">2039</option>
          <option value="2040">2040</option>
          <option value="2041">2041</option>
          <option value="2042">2042</option>
          <option value="2043">2043</option>
        </select>
      
    </div>
    <input
        placeholder="CVC"
        type="number"
        value={cvv}
        onChange={handleCvvChange}
        style={{ marginLeft: "10px", fontStyle: "italic", color: "black", width: "50%" }}
      />
    </div>
        </div>
    {/* Taksit Seçenekleri */}
    <div style={{ marginTop: '50px' }}>
    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }} className='table table-light'>
  <thead>
    <tr>
      <th></th>
      <th>Taksit Seçeneği</th>
      <th>Fiyat</th>
      <th>Toplam</th>
     
    </tr>
  </thead>
  <tbody>
    {taksitler.map((taksitOption, index) => (
      <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
        <td style={{ textAlign: "center" }}>
          <input
            type="radio"
            id={`taksit-${taksitOption.installmentNumber}`}
            name="taksit"
            value={taksitOption.installmentNumber}
            checked={selectedTaksit === taksitOption.installmentNumber}
            onChange={() => {
              setSelectedTaksit(taksitOption.installmentNumber);
              setSelectedTaksitPrice(taksitOption.totalPrice);

            }}
                      />
        </td>
        <td style={{ fontWeight: "bold", fontStyle: "italic" }}>
          {taksitOption.installmentNumber === 1
            ? "Tek Çekim"
            : `${taksitOption.installmentNumber} Ay Taksit`}
        </td>
        <td style={{ fontStyle: "italic" }}>{taksitOption.price} TL</td>
        <td style={{ fontStyle: "italic" }}>{taksitOption.totalPrice} TL</td>
      
      </tr>
    ))}
  </tbody>
</table>

    </div>

    </div>
    </div>


  
    {/* Sözleşmeler */}
    <div style={{ display: "flex", justifyContent: "space-between", marginTop: '20px' }}>
        <div style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)", padding: "20px", flex: 1, marginRight: "10px" , overflowY: "auto", maxHeight: "300px",fontSize: "18px"}}>
        1.TARAFLAR
İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve şartlar çerçevesinde imzalanmıştır. 
A.‘ALICI’ ; (sözleşmede bundan sonra "ALICI" olarak anılacaktır)
AD- SOYAD: ADRES: 
B.‘SATICI’ ; (sözleşmede bundan sonra "SATICI" olarak anılacaktır)
AD- SOYAD: YEŞİL DENİZ ÇİÇEKÇİLİK DEK. ORG. TRZ. SAN ve DIŞ TİC. LTD. ŞTİ. ADRES: Talatpaşa bulvarı No:20/C-D Alsancak / İZMİR
İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu siparişi onayladığı takdirde sipariş konusu bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.
2.TANIMLAR
İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı terimler karşılarındaki yazılı açıklamaları ifade edeceklerdir.
BAKAN : Gümrük ve Ticaret Bakanı’nı,
BAKANLIK : Gümrük ve Ticaret Bakanlığı’nı,
KANUN : 6502 sayılı Tüketicinin Korunması Hakkında Kanun’u,
YÖNETMELİK : Mesafeli Sözleşmeler Yönetmeliği’ni (RG:27.11.2014/29188)
HİZMET : Bir ücret veya menfaat karşılığında yapılan ya da yapılması taahhüt edilen mal sağlama dışındaki her türlü tüketici işleminin konusunu ,
SATICI : Ticari veya mesleki faaliyetleri kapsamında tüketiciye mal sunan veya mal sunan adına veya hesabına hareket eden şirketi,
ALICI : Bir mal veya hizmeti ticari veya mesleki olmayan amaçlarla edinen, kullanan veya yararlanan gerçek ya da tüzel kişiyi,
SİTE : SATICI’ya ait internet sitesini,
SİPARİŞ VEREN: Bir mal veya hizmeti SATICI’ya ait internet sitesi üzerinden talep eden gerçek ya da tüzel kişiyi,
TARAFLAR : SATICI ve ALICI’yı,
SÖZLEŞME : SATICI ve ALICI arasında akdedilen işbu sözleşmeyi,
MAL : Alışverişe konu olan taşınır eşyayı ve elektronik ortamda kullanılmak üzere hazırlanan yazılım, ses, görüntü ve benzeri gayri maddi malları ifade eder.
3.KONU
İşbu Sözleşme, ALICI’nın, SATICI’ya ait internet sitesi üzerinden elektronik ortamda siparişini verdiği aşağıda nitelikleri ve satış fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerini düzenler.
Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
4. SATICI BİLGİLERİ
YEŞİL DENİZ ÇİÇEKÇİLİK DEK. ORG. TRZ. SAN ve DIŞ TİC. LTD. ŞTİ. Talatpaşa bulvarı No:20/C-D Alsancak / İZMİR 02324214616 info@monjardincicek.com
5. ALICI BİLGİLERİ
Teslim edilecek kişi Teslimat Adresi Telefon Faks Eposta/kullanıcı adı
6. SİPARİŞ VEREN KİŞİ BİLGİLERİ
Ad/Soyad/Unvan
Adres Telefon Faks Eposta/kullanıcı adı
7. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ
1. Malın /Ürün/Ürünlerin/ Hizmetin temel özelliklerini (türü, miktarı, marka/modeli, rengi, adedi) SATICI’ya ait internet sitesinde yayınlanmaktadır. Satıcı tarafından kampanya düzenlenmiş ise ilgili ürünün temel özelliklerini kampanya süresince inceleyebilirsiniz. Kampanya tarihine kadar geçerlidir.
7.2. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
7.3. Sözleşme konusu mal ya da hizmetin tüm vergiler dâhil satış fiyatı aşağıda gösterilmiştir.



Ödeme Şekli ve Planı
Teslimat Adresi
Teslim Edilecek kişi
Fatura Adresi
Sipariş Tarihi
Teslimat tarihi
Teslim şekli

7.4.  Ürün sevkiyat masrafı olan kargo ücreti ALICI tarafından ödenecektir.
8. FATURA BİLGİLERİ
Ad/Soyad/Unvan
Adres Telefon Faks Eposta/kullanıcı adı Fatura teslim :Fatura sipariş teslimatı sırasında fatura adresine sipariş ile birlikte  teslim edilecektir.
9. GENEL HÜKÜMLER
9.1. ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda gerekli teyidi verdiğini kabul, beyan ve taahhüt eder. ALICI’nın; Ön Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış sözleşmesinin kurulmasından evvel, SATICI tarafından ALICI' ya verilmesi gereken adresi, siparişi verilen ürünlere ait temel özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme ve teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve taahhüt eder.
9.2. Sözleşme konusu her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile ALICI' nın yerleşim yeri uzaklığına bağlı olarak internet sitesindeki ön bilgiler kısmında belirtilen süre zarfında ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürünün ALICI’ya teslim edilememesi durumunda, ALICI’nın sözleşmeyi feshetme hakkı saklıdır. 
9.3. SATICI, Sözleşme konusu ürünü eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri, kullanım kılavuzları işin gereği olan bilgi ve belgeler ile teslim etmeyi, her türlü ayıptan arî olarak yasal mevzuat gereklerine göre sağlam, standartlara uygun bir şekilde işi doğruluk ve dürüstlük esasları dâhilinde ifa etmeyi, hizmet kalitesini koruyup yükseltmeyi, işin ifası sırasında gerekli dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile hareket etmeyi kabul, beyan ve taahhüt eder.
9.4. SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan ALICI’yı bilgilendirmek ve açıkça onayını almak suretiyle eşit kalite ve fiyatta farklı bir ürün tedarik edebilir.
9.5. SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, öğrendiği tarihten itibaren 3 gün içinde yazılı olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam bedeli ALICI’ya iade edeceğini kabul, beyan ve taahhüt eder. 
9.6. ALICI, Sözleşme konusu ürünün teslimatı için işbu Sözleşme’yi elektronik ortamda teyit edeceğini, herhangi bir nedenle sözleşme konusu ürün bedelinin ödenmemesi ve/veya banka kayıtlarında iptal edilmesi halinde, SATICI’nın sözleşme konusu ürünü teslim yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt eder.
9.7. ALICI, Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa tesliminden sonra ALICI'ya ait kredi kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme konusu ürün bedelinin ilgili banka veya finans kuruluşu tarafından SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade edeceğini kabul, beyan ve taahhüt eder.
9.8. SATICI, tarafların iradesi dışında gelişen, önceden öngörülemeyen ve tarafların borçlarını yerine getirmesini engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir sebepler halleri nedeni ile sözleşme konusu ürünü süresi içinde teslim edemez ise, durumu ALICI'ya bildireceğini kabul, beyan ve taahhüt eder. ALICI da siparişin iptal edilmesini, sözleşme konusu ürünün varsa emsali ile değiştirilmesini ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesini SATICI’dan talep etme hakkını haizdir. ALICI tarafından siparişin iptal edilmesi halinde ALICI’nın nakit ile yaptığı ödemelerde, ürün tutarı 14 gün içinde kendisine nakden ve defaten ödenir. ALICI’nın kredi kartı ile yaptığı ödemelerde ise, ürün tutarı, siparişin ALICI tarafından iptal edilmesinden sonra 14 gün içerisinde ilgili bankaya iade edilir. ALICI, SATICI tarafından kredi kartına iade edilen tutarın banka tarafından ALICI hesabına yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın hesaplarına yansıması halinin tamamen banka işlem süreci ile ilgili olduğundan, ALICI, olası gecikmeler için SATICI’yı sorumlu tutamayacağını kabul, beyan ve taahhüt eder.
9.9. SATICININ, ALICI tarafından siteye kayıt formunda belirtilen veya daha sonra kendisi tarafından güncellenen adresi, e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim bilgileri üzerinden mektup, e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim, pazarlama, bildirim ve diğer amaçlarla ALICI’ya ulaşma hakkı bulunmaktadır. ALICI, işbu sözleşmeyi kabul etmekle SATICI’nın kendisine yönelik yukarıda belirtilen iletişim faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir.
9.10. ALICI, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. Teslimden sonra mal/hizmetin özenle korunması borcu, ALICI’ya aittir. Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura iade edilmelidir.
9.11. ALICI ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi olmaması veya ürünün ALICI’ya tesliminden evvel, siparişte kullanılan kredi kartına ilişkin güvenlik açığı tespit edilmesi halinde, SATICI, kredi kartı hamiline ilişkin kimlik ve iletişim bilgilerini, siparişte kullanılan kredi kartının bir önceki aya ait ekstresini yahut kart hamilinin bankasından kredi kartının kendisine ait olduğuna ilişkin yazıyı ibraz etmesini ALICI’dan talep edebilir. ALICI’nın talebe konu bilgi/belgeleri temin etmesine kadar geçecek sürede sipariş dondurulacak olup, mezkur taleplerin 24 saat içerisinde karşılanmaması halinde ise SATICI, siparişi iptal etme hakkını haizdir.
9.12. ALICI, SATICI’ya ait internet sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin gerçeğe uygun olduğunu, SATICI’nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları, SATICI’nın ilk bildirimi üzerine derhal, nakden ve defaten tazmin edeceğini beyan ve taahhüt eder.
9.13. ALICI, SATICI’ya ait internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler tamamen ve münhasıran ALICI’yı bağlayacaktır.
9.14. ALICI, SATICI’ya ait internet sitesini hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının maddi ve manevi haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye başkalarının hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.) işlemlerde bulunamaz.
9.15. SATICI’ya ait internet sitesinin üzerinden, SATICI’nın kendi kontrolünde olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu ve/veya işlettiği başka web sitelerine ve/veya başka içeriklere link verilebilir. Bu linkler ALICI’ya yönlenme kolaylığı sağlamak amacıyla konmuş olup herhangi bir web sitesini veya o siteyi işleten kişiyi desteklememekte ve Link verilen web sitesinin içerdiği bilgilere yönelik herhangi bir garanti niteliği taşımamaktadır.
9.16. İşbu sözleşme içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, SATICI’yı bu ihlallerin hukuki ve cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına intikal ettirilmesi halinde, SATICI’nın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
10. CAYMA HAKKI
10.1. ALICI; mesafeli sözleşmenin mal satışına ilişkin olması durumunda, ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir. Hizmet sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir. ALICI, iş bu sözleşmeyi kabul etmekle, cayma hakkı konusunda bilgilendirildiğini peşinen kabul eder.
10.2. Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış olması şarttır. Bu hakkın kullanılması halinde, 
a) 3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
b) İade formu,
c) İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.
d) SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı iade almakla yükümlüdür.
e) ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir. 
f) Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
11. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER
ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan, iç giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri, tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan mallar, ALICI’ya teslim edilmesinin ardından ALICI tarafından ambalajı açıldığı takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan ürünler, teslim edildikten sonra başka ürünlerle karışan vedoğası gereği ayrıştırılması mümkün olmayan ürünler, Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınlara ilişkin mallar, Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallar,ile ses veya görüntü kayıtlarının, kitap, dijital içerik, yazılım programlarının, veri kaydedebilme ve veri depolama cihazlarının, bilgisayar sarf malzemelerinin, ambalajının ALICI tarafından açılmış olması halinde iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin cayma hakkının kullanılması daYönetmelik gereği mümkün değildir.
Kozmetik ve kişisel bakım ürünleri, iç giyim ürünleri, mayo, bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.) iade edilebilmesi için ambalajlarının açılmamış, denenmemiş, bozulmamış ve kullanılmamış olmaları gerekir. 
 
 
12. TEMERRÜT HALİ VE HUKUKİ SONUÇLARI
ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt eder
13. YETKİLİ MAHKEME
İşbu sözleşmeden doğan uyuşmazlıklarda şikayet ve itirazlar, aşağıdaki kanunda belirtilen parasal sınırlar dâhilinde tüketicinin yerleşim yerinin bulunduğu veya tüketici işleminin yapıldığı yerdeki tüketici sorunları hakem heyetine veya tüketici mahkemesine yapılacaktır. Parasal sınıra ilişkin bilgiler aşağıdadır: 
28/05/2014 tarihinden itibaren geçerli olmak üzere:
a) 6502 sayılı Tüketicinin Korunması Hakkında Kanun’un 68. Maddesi gereği değeri 2.000,00 (ikibin) TL’nin altında olan uyuşmazlıklarda ilçe tüketici hakem heyetlerine,
b) Değeri 3.000,00(üçbin)TL’ nin altında bulunan uyuşmazlıklarda il tüketici hakem heyetlerine,
c) Büyükşehir statüsünde bulunan illerde ise değeri 2.000,00 (ikibin) TL ile 3.000,00(üçbin)TL’ arasındaki uyuşmazlıklarda il tüketici hakem heyetlerine başvuru yapılmaktadır. İşbu Sözleşme ticari amaçlarla yapılmaktadır.
14. YÜRÜRLÜK
ALICI, Site üzerinden verdiği siparişe ait ödemeyi gerçekleştirdiğinde işbu sözleşmenin tüm şartlarını kabul etmiş sayılır. SATICI, siparişin gerçekleşmesi öncesinde işbu sözleşmenin sitede ALICI tarafından okunup kabul edildiğine dair onay alacak şekilde gerekli yazılımsal düzenlemeleri yapmakla yükümlüdür. 
SATICI:
ALICI:
TARİH:
        </div>
        <div style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)", padding: "20px", flex: 1,overflowY: "auto", maxHeight: "300px",fontSize: "18px"  }}>
        GİZLİLİK VE GÜVENLİK POLİTİKASI
 
 Mağazamızda verilen tüm servisler Talatpaşa bulvarı No:20/C-D Alsancak / İZMİR adresinde kayıtlı YEŞİL DENİZ ÇİÇEKÇİLİK DEK. ORG. TRZ. SAN ve DIŞ TİC. LTD. ŞTİ. firmamıza aittir ve firmamız tarafından işletilir. 
 
 Firmamız, çeşitli amaçlarla kişisel veriler toplayabilir. Aşağıda, toplanan kişisel verilerin nasıl ve ne şekilde toplandığı, bu verilerin nasıl ve ne şekilde korunduğu belirtilmiştir. 
 
 Üyelik veya Mağazamız üzerindeki çeşitli form ve anketlerin doldurulması suretiyle üyelerin kendileriyle ilgili bir takım kişisel bilgileri (isim-soy isim, firma bilgileri, telefon, adres veya e-posta adresleri gibi) Mağazamız tarafından işin doğası gereği toplanmaktadır. 
 
 Firmamız bazı dönemlerde müşterilerine ve üyelerine kampanya bilgileri, yeni ürünler hakkında bilgiler, promosyon teklifleri gönderebilir. Üyelerimiz bu gibi bilgileri alıp almama konusunda her türlü seçimi üye olurken yapabilir, sonrasında üye girişi yaptıktan sonra hesap bilgileri bölümünden bu seçimi değiştirilebilir ya da kendisine gelen bilgilendirme iletisindeki linkle bildirim yapabilir. 
 
 Mağazamız üzerinden veya eposta ile gerçekleştirilen onay sürecinde, üyelerimiz tarafından mağazamıza elektronik ortamdan iletilen kişisel bilgiler, Üyelerimiz ile yaptığımız "Kullanıcı Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklanmayacaktır.
 
 Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili çıkabilecek sorunların veya uyuşmazlıkların hızla çözülmesi için, Firmamız, üyelerinin IP adresini kaydetmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.
 
 Firmamız, Üyelik Sözleşmesi ile belirlenen amaçlar ve kapsam dışında da, talep edilen bilgileri kendisi veya işbirliği içinde olduğu kişiler tarafından doğrudan pazarlama yapmak amacıyla kullanabilir.  Kişisel bilgiler, gerektiğinde kullanıcıyla temas kurmak için de kullanılabilir. Firmamız tarafından talep edilen bilgiler veya kullanıcı tarafından sağlanan bilgiler veya Mağazamız üzerinden yapılan işlemlerle ilgili bilgiler; Firmamız ve işbirliği içinde olduğu kişiler tarafından, "Üyelik Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında da, üyelerimizin kimliği ifşa edilmeden çeşitli istatistiksel değerlendirmeler, veri tabanı oluşturma ve pazar araştırmalarında kullanılabilir.
 
 Firmamız, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
  
 KREDİ KARTI GÜVENLİĞİ
  
 Firmamız, alışveriş sitelerimizden alışveriş yapan kredi kartı sahiplerinin güvenliğini ilk planda tutmaktadır. Kredi kartı bilgileriniz hiçbir şekilde sistemimizde saklanmamaktadır.
  
 İşlemler sürecine girdiğinizde güvenli bir sitede olduğunuzu anlamak için dikkat etmeniz gereken iki şey vardır. Bunlardan biri tarayıcınızın en alt satırında bulunan bir anahtar ya da kilit simgesidir. Bu güvenli bir internet sayfasında olduğunuzu gösterir ve her türlü bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak satış işlemleri sürecine bağlı olarak ve verdiğiniz talimat istikametinde kullanılır. Alışveriş sırasında kullanılan kredi kartı ile ilgili bilgiler alışveriş sitelerimizden bağımsız olarak 128 bit SSL (Secure Sockets Layer) protokolü ile şifrelenip sorgulanmak üzere ilgili bankaya ulaştırılır. Kartın kullanılabilirliği onaylandığı takdirde alışverişe devam edilir. Kartla ilgili hiçbir bilgi tarafımızdan görüntülenemediğinden ve kaydedilmediğinden, üçüncü şahısların herhangi bir koşulda bu bilgileri ele geçirmesi engellenmiş olur.
 Online olarak kredi kartı ile verilen siparişlerin ödeme/fatura/teslimat adresi bilgilerinin güvenilirliği firmamiz tarafından Kredi Kartları Dolandırıcılığı'na karşı denetlenmektedir. Bu yüzden, alışveriş sitelerimizden ilk defa sipariş veren müşterilerin siparişlerinin tedarik ve teslimat aşamasına gelebilmesi için öncelikle finansal ve adres/telefon bilgilerinin doğruluğunun onaylanması gereklidir. Bu bilgilerin kontrolü için gerekirse kredi kartı sahibi müşteri ile veya ilgili banka ile irtibata geçilmektedir.
 Üye olurken verdiğiniz tüm bilgilere sadece siz ulaşabilir ve siz değiştirebilirsiniz. Üye giriş bilgilerinizi güvenli koruduğunuz takdirde başkalarının sizinle ilgili bilgilere ulaşması ve bunları değiştirmesi mümkün değildir. Bu amaçla, üyelik işlemleri sırasında 128 bit SSL güvenlik alanı içinde hareket edilir. Bu sistem kırılması mümkün olmayan bir uluslararası bir şifreleme standardıdır.
 
 Bilgi hattı veya müşteri hizmetleri servisi bulunan ve açık adres ve telefon bilgilerinin belirtildiği İnternet alışveriş siteleri günümüzde daha fazla tercih edilmektedir. Bu sayede aklınıza takılan bütün konular hakkında detaylı bilgi alabilir, online alışveriş hizmeti sağlayan firmanın güvenirliği konusunda daha sağlıklı bilgi edinebilirsiniz. 
  
 Not: İnternet alışveriş sitelerinde firmanın açık adresinin ve telefonun yer almasına dikkat edilmesini tavsiye ediyoruz. Alışveriş yapacaksanız alışverişinizi yapmadan ürünü aldığınız mağazanın bütün telefon / adres bilgilerini not edin. Eğer güvenmiyorsanız alışverişten önce telefon ederek teyit edin. Firmamıza ait tüm online alışveriş sitelerimizde firmamıza dair tüm bilgiler ve firma yeri belirtilmiştir.
  
 MAİL ORDER KREDİ KART BİLGİLERİ GÜVENLİĞİ
  
 Kredi kartı mail-order yöntemi ile bize göndereceğiniz kimlik ve kredi kart bilgileriniz firmamız tarafından gizlilik prensibine göre saklanacaktır. Bu bilgiler olası banka ile oluşubilecek kredi kartından para çekim itirazlarına karşı 60 gün süre ile bekletilip daha sonrasında imha edilmektedir. Sipariş ettiğiniz ürünlerin bedeli karşılığında bize göndereceğiniz tarafınızdan onaylı mail-order formu bedeli dışında herhangi bir bedelin kartınızdan çekilmesi halinde doğal olarak bankaya itiraz edebilir ve bu tutarın ödenmesini engelleyebileceğiniz için bir risk oluşturmamaktadır. 
 
 
 ÜÇÜNCÜ TARAF WEB SİTELERİ VE UYGULAMALAR
 Mağazamız,  web sitesi dâhilinde başka sitelere link verebilir. Firmamız, bu linkler vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır. Firmamıza ait sitede yayınlanan reklamlar, reklamcılık yapan iş ortaklarımız aracılığı ile kullanıcılarımıza dağıtılır. İş bu sözleşmedeki Gizlilik Politikası Prensipleri, sadece Mağazamızın kullanımına ilişkindir, üçüncü taraf web sitelerini kapsamaz. 
 
 İSTİSNAİ HALLER
 Aşağıda belirtilen sınırlı hallerde Firmamız, işbu "Gizlilik Politikası" hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;
 1.Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili hukuki otorite tarafından çıkarılan ve yürürlülükte olan hukuk kurallarının getirdiği zorunluluklara uymak;
 2.Mağazamızın kullanıcılarla akdettiği "Üyelik Sözleşmesi"'nin ve diğer sözleşmelerin gereklerini yerine getirmek ve bunları uygulamaya koymak amacıyla;
 3.Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesi;
 4.Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin gerekli olduğu hallerdir. 
 
 E-POSTA GÜVENLİĞİ
 Mağazamızın Müşteri Hizmetleri’ne, herhangi bir siparişinizle ilgili olarak göndereceğiniz e-postalarda, asla kredi kartı numaranızı veya şifrelerinizi yazmayınız. E-postalarda yer alan bilgiler üçüncü şahıslar tarafından görülebilir. Firmamız e-postalarınızdan aktarılan bilgilerin güvenliğini hiçbir koşulda garanti edemez.
 
 TARAYICI ÇEREZLERİ 
 Firmamız, mağazamızı ziyaret eden kullanıcılar ve kullanıcıların web sitesini kullanımı hakkındaki bilgileri teknik bir iletişim dosyası (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte saklanmak üzere bir internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır. Teknik iletişim dosyası site hakkında durum ve tercihleri saklayarak İnternet'in kullanımını kolaylaştırır.
 
 Teknik iletişim dosyası,  siteyi kaç kişinin ziyaret ettiğini, bir kişinin siteyi hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar sitede kaldıkları hakkında istatistiksel bilgileri elde etmeye ve kullanıcılar için özel tasarlanmış kullanıcı sayfalarından  dinamik olarak reklam ve içerik üretilmesine yardımcı olur. Teknik iletişim dosyası, ana bellekte veya e-postanızdan veri veya başkaca herhangi bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya teknik iletişim dosyasının gönderildiğinde uyarı verilmesini sağlayacak biçimde ayarları değiştirebilirler.
 
 Firmamız, işbu "Gizlilik Politikası" hükümlerini dilediği zaman sitede yayınlamak veya kullanıcılara elektronik posta göndermek veya sitesinde yayınlamak suretiyle değiştirebilir. Gizlilik Politikası hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.
 
 Gizlilik politikamız ile ilgili her türlü soru ve önerileriniz için ……………….. adresine email gönderebilirsiniz. Firmamız’a ait aşağıdaki iletişim bilgilerinden ulaşabilirsiniz.
 
 Firma Ünvanı: YEŞİL DENİZ ÇİÇEKÇİLİK DEK. ORG. TRZ. SAN ve DIŞ TİC. LTD. ŞTİ.
 Adres: Talatpaşa bulvarı No:20/C-D Alsancak / İZMİR
 Eposta: info@monjardincicek.com
 Tel: 02324214616
        </div>
        <div style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)", padding: "20px", flex: 1,overflowY: "auto", maxHeight: "300px",fontSize: "18px"  }}>
        TÜKETİCİ HAKLARI – CAYMA – İPTAL İADE KOŞULLARI

GENEL:
1.Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.
2.Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir. 
3.Ürün sevkiyat masrafı olan kargo ücretleri alıcılar tarafından ödenecektir.
4.Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile alıcının gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürün teslim edilmez ise, Alıcılar sözleşmeyi sona erdirebilir. 
5.Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere uygun ve varsa garanti belgesi, kullanım klavuzu gibi belgelerle teslim edilmek zorundadır. 
6.Satın alınan ürünün satılmasının imkansızlaşması durumunda, satıcı bu durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak alıcıya bu durumu bildirmek zorundadır. 14 gün içinde de toplam bedel Alıcı’ya iade edilmek zorundadır. 

SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE: 
7.Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında iptal ederse, Satıcının ürünü teslim yükümlülüğü sona erer.

KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:
8.Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı tespit edilirse ve satılan ürün bedeli ilgili banka veya finans kuruluşu tarafından Satıcı'ya ödenmez ise, Alıcı, sözleşme konusu ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade etmek zorundadır. 

ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE: 
9.Satıcı’nın öngöremeyeceği mücbir sebepler oluşursa ve ürün süresinde teslim edilemez ise, durum Alıcı’ya bildirilir. Alıcı, siparişin iptalini, ürünün benzeri ile değiştirilmesini veya engel ortadan kalkana dek teslimatın ertelenmesini talep edebilir. Alıcı siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 14 gün içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi kartı ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 14 gün içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının hesabına 2-3 hafta içerisinde aktarması olasıdır. 

ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ: 
10.Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. ALICI , Teslimden sonra mal/hizmeti özenle korunmak zorundadır. Cayma hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Ürünle birlikte Fatura da iade edilmelidir.

CAYMA HAKKI:
11.ALICI; satın aldığı ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya aşağıdaki iletişim bilgileri üzerinden bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir.

12.SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM BİLGİLERİ:
ŞİRKET 
ADI/UNVANI: YEŞİL DENİZ ÇİÇEKÇİLİK DEK. ORG. TRZ. SAN ve DIŞ TİC. LTD. ŞTİ. ADRES: Talatpaşa bulvarı No:20/C-D Alsancak / İZMİR EPOSTA: info@monjardincicek.com TEL: 02324214616

CAYMA HAKKININ SÜRESİ:
13.Alıcı, satın aldığı eğer bir hizmet ise, bu 14 günlük süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. 
14.Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir.
15.Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış olması şarttır. 

CAYMA HAKKININ KULLANIMI: 
16. 3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
17. İade formu, İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.

İADE KOŞULLARI:
18.SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı iade almakla yükümlüdür.
19.ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir. 
20.Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.

CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER: 
21.ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan ve geri gönderilmeye müsait olmayan, iç giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri, tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan mallar, ALICI’ya teslim edilmesinin ardından ALICI tarafından ambalajı açıldığı takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan ürünler, teslim edildikten sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan ürünler, Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınlara ilişkin mallar, Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallar, ile ses veya görüntü kayıtlarının, kitap, dijital içerik, yazılım programlarının, veri kaydedebilme ve veri depolama cihazlarının, bilgisayar sarf malzemelerinin, ambalajının ALICI tarafından açılmış olması halinde iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin cayma hakkının kullanılması da Yönetmelik gereği mümkün değildir.
22.Kozmetik ve kişisel bakım ürünleri, iç giyim ürünleri, mayo, bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.) iade edilebilmesi için ambalajlarının açılmamış, denenmemiş, bozulmamış ve kullanılmamış olmaları gerekir. 

TEMERRÜT HALİ VE HUKUKİ SONUÇLARI
23.ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her koşulda ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul eder. 

ÖDEME VE TESLİMAT
24.Banka Havalesi veya EFT (Elektronik Fon Transferi) yaparak, Türkiye İş Bankası TR740006400000134201793557 no.’lu hesabımızdan (TL) yapabilirsiniz.
25.Sitemiz üzerinden kredi kartlarınız ile, Her türlü kredi kartınıza online tek ödeme ya da online taksit imkânlarından yararlanabilirsiniz. Online ödemelerinizde siparişiniz sonunda kredi kartınızdan tutar çekim işlemi gerçekleşecektir.
        </div>
      </div>
      <div style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
        <input type="checkbox" checked={checkedSozlesme} onChange={() => setCheckedSozlesme(!checkedSozlesme)} style={{ marginRight: "10px" }} />
        <p style={{ margin: 0 }}>Sözleşmeleri okudum ve onaylıyorum</p>
      </div>
    

      <button className='satinal-button' style={{float:"right", marginTop:"20px"}} onClick={handleBuyClick}>Satın Al</button>
    
    </div>
    
  );
};

export default WithNavbar(Order);


