import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from './config/Constants';

const Footer = () => {

    const [categoryList, setCategoryList] = useState([]);

  const fetchCategoryList = async () => {
    try {
      const response = await fetch(baseUrl+`api/Category/GetMainCategories`);
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      const categoryData = data.data;
      setCategoryList(categoryData);
    } catch (error) {
      //console.error(error);
     toast.error('Lütfen Daha Sonra Tekrar Deneyiniz', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;     
    }
  };
  useEffect(() => {
    fetchCategoryList();
  }, []);


  const handleClickGoogle = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.appelier.monjardin";
  };
  const handleClickIos = () => {
    window.location.href = "https://apps.apple.com/tr/app/mon-jardin/id6449429627?l=tr";
  };


  return (
    <div style={{ height: "270px",marginBottom:"50px" }}>
  <hr />
  <div style={{ display: 'flex', marginTop: "50px", marginBottom: "50px" }}>
    <div className='mobile-footer-div' style={{ marginLeft: "3%" }}>
      <p className='footer-title'>Mon Jardin</p>
      <p className='footer-url'><img src={"/images/footer.png"} className='footer-url-image' /> www.mjcicek.com</p>
      <img src={"/images/iyzico-logo-1.png"} alt=""  />
      <img src={"/images/iyzico-logo-2.png"} className='mobile-iyzicologo2' alt=""  />
     <div style={{textAlign:"center", marginTop:"10px"}}>

     <img src={"/images/android-qr.svg"} alt=""  style={{marginRight:"40px",width:"100px" , height:"100px"}} className='mobile-app-qr'/>
     <img src={"/images/ios-qr.svg"} alt=""  className='mobile-app-qr' style={{width:"100px" , height:"100px"}}/>

     </div>
     <div style={{textAlign:"center"}}>
     <img src={"/images/google.svg"} alt="" style={{marginTop:"20px", marginRight:"40px"}} className='mobile-app-img-left'  onClick={handleClickGoogle}/>
     <img src={"/images/apple.svg"} alt=""  className='mobile-app-img'     onClick={handleClickIos} />
</div>
    </div>
    <div className='mobile-footer' style={{ width: 250, marginLeft: "40px" , marginTop:"25px"}}>
      <p className='footer-sub-title' style={{ marginLeft: "7px" }}>KURUMSAL</p>
      <div style={{ marginLeft: "7px" }}>
      <Link to="/Morethan/hakkimizda" className='footer-text'><p className='footer-text'>Hakkımızda</p></Link>
        <Link to="/Morethan/satis-sozlesmesi" className='footer-text'><p className='footer-text'>Mesafeli Satış Sözleşmesi</p></Link>
        <Link to="/Morethan/gizlilik-politikasi" className='footer-text'><p className='footer-text'>Gizlilik Politikası</p></Link>
        <Link to="/Morethan/teslimat-iade-bildirimi" className='footer-text'><p className='footer-text'>Teslimat-İade Bildirimi</p></Link>
       
        <Link to={`/Blog`} className='footer-text'><p className='footer-text'>Blog</p></Link>
        <Link to={`/Contact`} className='footer-text'><p className='footer-text'>İletişim</p></Link>


      </div>
    </div>
    <div>
      <p className='footer-sub-title footer-site-mobile'style={{ marginTop:"25px"}}  >SİTE</p>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Link to={`/`} className='footer-text' style={{width: '100%',}}><p className='footer-text'>Ana Sayfa</p></Link>
        <div className='footer-container'>
  {categoryList?.length > 0 && categoryList.map((category, index) => (
    <div key={category.id} className='footer-item' style={{ width: categoryList.length > 10 ? '20%' : '100%', boxSizing: 'border-box' }}>
      <Link to={`/List/${category.id}`} className='footer-link' style={{textDecoration:"none"}}>
        <p className='footer-text'>{category.name}</p>
      </Link>
    </div>
  ))}
</div>

      </div>
    </div>
  </div>
</div>
  );
}

export default Footer;



