import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import AdminMenu from './adminMenu';
import { useNavigate, useLocation } from "react-router-dom";
import { getToken, getUserInfo } from "./service/AuthService";


const PrivateComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(null);



  const handleMenuClick = (menuValue) => {
    setSelectedMenu(menuValue);
  };

  return (
    <>
    
    <div style={{ display: "flex" }}>
          <AdminMenu style={{ flex: '0 0 auto', width: 256, overflowY: 'auto', display: 'flex', height:"100%" }} selectedMenu={selectedMenu} onMenuClick={handleMenuClick} />
          <div style={{ flex: '1', }}>
            <Outlet />
          </div>
    </div>
    
    </>
  );
};

export default PrivateComponent;


