import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../config/Constants';
import { getToken } from "../service/AuthService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, collection, getDocs,doc,getDoc,setDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

export default function AdminSmsList() {
    const [savedSms, setSavedSms] = useState([]);
    let token = getToken();
 
    const firebaseConfig = {
        apiKey: "AIzaSyBVljeCIm_rhZBx0522TXkNa4G4ufKoMLY",
        authDomain: "monjardin-7cc13.firebaseapp.com",
        projectId: "monjardin-7cc13",
        storageBucket: "monjardin-7cc13.appspot.com",
        messagingSenderId: "81286471664",
        appId: "1:81286471664:web:51a5c1de2c61b4551b4735",
        measurementId: "G-5BW4TRMJDR"
      }; 
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);


  const fetchSmsList = async () => {
    try {
      const collectionRef = collection(firestore, 'smslist'); 
      const querySnapshot = await getDocs(collectionRef);
  
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs.map((doc) => doc.data());
        console.log("data", data);
        setSavedSms(data);
      } else {
        console.log("No documents found in the 'sms' collection.");
      }
    } catch (error) {
      console.error("Error getting documents:", error);
    }
  };
  

  useEffect(() => {
    fetchSmsList();
  }, []);
 
  
  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); 
  };

 

return (
  <div  style={{ margin: "50px" }}>
                <ToastContainer />
   <h1  className='baslik'>Mon Jardin</h1>

        {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}
      
    <button
      style={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 10px",
        borderRadius: "5px",
        backgroundColor: "#893694",
        border: "1px solid gray",
        boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",
        right: "20px",
        zIndex: "999",
        float:"right",
        marginTop:"30px"
      }}
       onClick={() => navigate("/adminaddsms")}
    >
      <i className="fas fa-edit" style={{ marginRight: "5px" }}></i> Sms Gönder
    </button>
    <div style={{marginTop:"50px"}}>

      {savedSms && (
      <table className='table table-light'>
      <thead>
      </thead>
      <tbody>
        {savedSms.map((item) => (
        
          <React.Fragment key={item.id}>
          
            <tr style={{ borderBottom: "1px solid #ccc"}}>
            
              <td style={{verticalAlign:"middle",width:"55%"}}>{item.text}</td>

            </tr>
            
          </React.Fragment>
        ))}
        
      </tbody>
    
    </table>
      )}
    </div>
</div>
)}