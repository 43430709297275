import React, { useState ,useEffect} from 'react';
import AdminDashboard from './AdminDashboard';

const Adminpanel = () => {

  return (
   <div style={{ color:"black",  fontFamily: 'Times New Roman',margin:"5%"}} className='admin-panel-right'>
       <AdminDashboard/>
   </div>
   
      
  ) };
export default Adminpanel;
/*
export default function Adminpanel() {


const [lastProduct, setLastProduct] = useState([]);
const [lastBlog, setLastBlog] = useState([]);
const [totalUserComments, setTotalUserComments] = useState("");
const [totalUserRecords, setTotalUserRecords] = useState("");


const navigate = useNavigate();

const logoutHandler = () => {
  resetUserSession();
  navigate("/");
};

const token = getToken();
useEffect(() => {

  const fetchLastProduct = async () => {
    try {
      // İstek yapma kodu
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await fetch(baseUrl+`api/Dashboard/LastProduct`, requestOptions);
      
      // Başarılı yanıtın kontrolü
      if (response.ok) {
        const data = await response.json();
        const productData = data.data;
        setLastProduct(productData);
      } else {
        throw new Error("Sunucudan geçersiz bir yanıt alındı.");
      }
    } catch (error) {
     // console.error("fetchLastProduct hatası:", error);
    }
  };
   
  const fetchLastBlog = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await fetch(baseUrl+ `api/Dashboard/LastBlog`, requestOptions);
      const data = await response.json();
      const blog = data.data;
      setLastBlog(blog);
    } catch (error) {
     // console.error("fetchLastBlog hatası:", error);
      // Hata durumunda yapılacak işlemler
    }
  };

  const fetchTotalUserComments = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await fetch(baseUrl+`api/Dashboard/TotalUserComments`, requestOptions);
      const data = await response.json();
      const totalUserCommentsCount = data.data;
      setTotalUserComments(totalUserCommentsCount);
    } catch (error) {
     // console.error("totalUserComments hatası:", error);
      // Hata durumunda yapılacak işlemler
    }
  };


  const fetchTotalUserRecords = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await fetch(baseUrl+`api/Dashboard/TotalUserRecords`, requestOptions);
      const data = await response.json();
      const totalUserRecordsCount = data.data;
      setTotalUserRecords(totalUserRecordsCount);
    } catch (error) {
      //console.error("totalUserRecordsCount hatası:", error);
      // Hata durumunda yapılacak işlemler
    }
  };

  fetchTotalUserRecords();
  fetchLastProduct();
  fetchLastBlog();
  fetchTotalUserComments();
}, []);

const user=getUserInfo();

  return (
    <>
      <div  style={{display:"flex" }}>
      <div style={{float:"left", width:"25%"}} className='admin-panel-left'>
      <Navbar  expand="lg" fixed="top"   >
        <Container>  
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="navbar-admin-left">
            <Nav className="me-auto">
            <nav>
            <div className="menu-items-admin">
            {user && (
  <div className="text-center">
    <img src="/images/person.png" alt="" width={"45"} height={"45"} />
    <p className='menu-items-admin-link'>{user.firstName}</p>
    <p className='menu-items-admin-link'>{user.email}</p>
  </div>
)}<div className="menu-container" style={{ maxHeight: "500px", overflowY: "auto" }}>
<NavLink className="menu-items-admin-link" to='/AdminProductList' style={{ fontStyle: "italic" }}>Stok Kontrol</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/AdminSearch' style={{ fontStyle: "italic" }}>Kalemler</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/AdminAddProduct' style={{ fontStyle: "italic" }}>Yeni Kalem Ekle</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/EditCategory' style={{ fontStyle: "italic" }}>Kategori Düzenleme</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/adminallblog' style={{ fontStyle: "italic" }}>Blog Düzenleme</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/adminallnotification' style={{ fontStyle: "italic" }}>Bildirimler</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/EditSlider' style={{ fontStyle: "italic" }}>Duyuru</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/adminorderlist' style={{ fontStyle: "italic" }}>Siparişler</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/adminshipmentlist' style={{ fontStyle: "italic" }}>Kargo Düzenle</NavLink>
<hr />

<NavLink className="menu-items-admin-link" to='/admincampaignlist' style={{ fontStyle: "italic" }}>Kampanya Düzenle</NavLink>
<hr />
<NavLink className="menu-items-admin-link" to='/adminsmslist' style={{ fontStyle: "italic" }}>Sms</NavLink>
<hr />
 

{token ? <NavLink className="menu-items-admin-link" to='/' value="Logout" onClick={logoutHandler} >Çıkış Yap</NavLink> : ""}
</div>

             {/* 
               <NavLink className="menu-items-admin-link" to='/AdminAllProductList' style={{fontStyle:"italic"}}>Tüm Ürünler</NavLink>
               <hr/>
             <NavLink className="menu-items-admin-link" to='/Stock' style={{fontStyle:"italic"}}>Stok Ekle/Çıkar</NavLink>
              <hr/>
              
             
             
  
             
            </div>
        
        </nav>
            </Nav>
          </Navbar.Collapse> 
        </Container>
      </Navbar>
      </div>
      <div style={{float:"right", width:"75%", color:"black",  fontFamily: 'Times New Roman',margin:"5%"}} className='admin-panel-right'>
        <AdminDashboard/>
      </div>
  
      </div>
    </>
  );
}

*/

